import * as Firestore from 'firebase/firestore'
import { Invitation } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundChangedRunCommand } from './command-player.js'
import { resendInvitation } from './https-calls.js'
import verifyAndUpdateSeatsCount from './helpers/verify-and-update-seats-count.js'

// ---------------------------------------------------------------------------------------------------------------------
// InvitationChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const InvitationChangedCommand = taggedSum('InvitationChangedCommand', {
    Inbound: { invitation: 'Invitation' },
    Outbound: {
        invitation: 'Invitation',
        id: StringTypes.Id,
        changes: 'Object',
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------
const getItem = c => c.invitation

/*
 * A InvitationChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Invitation)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundChangedRunCommand(resources, command, Invitation, ReduxActions.invitationAdded, getItem)

/*
 * The user wants to resend an invitation: an InvitationChangedCommand.Outbound; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError, firestore, runTransaction, getState } = resources

    const _changeUserInvitation = (changes, userId, organizationId) => {
        const userInvitationPath = `/users/${userId}/invitations/${organizationId}`
        return runTransaction(async tx => tx.update(Firestore.doc(firestore, userInvitationPath), changes))
    }

    try {
        const { invitation, changes } = command
        const { id, organizationId, inviteeEmail, userId, organizationRole: previousOrganizationRole } = invitation

        if (!(changes.resentTimestamp || changes.organizationRole || changes.projectIds))
            throw new Error("Don't understand command")

        const oranizationInvitationPath = `/organizations/${organizationId}/invitations/${id}`

        // If there's resentTimestamp we just want to resend the same invitation with no changes
        if (changes.resentTimestamp) {
            await resendInvitation(organizationId, id)

            // show the toast
            const toastLabel = `Invitation sent to ${inviteeEmail}`
            const toast = { id: invitation.id, severity: 'success', toastLabel, showUndo: false }
            dispatch(ReduxActions.toastAdded(toast))
        } else {
            // If we change invitation from Guest to either Collaborator or Admin role
            if (previousOrganizationRole === 'Guest' && changes.organizationRole !== 'Guest') {
                // check if we have enough seats and update if more needed
                verifyAndUpdateSeatsCount(getState(), 1)
            }

            await runTransaction(async tx =>
                tx.update(Firestore.doc(firestore, oranizationInvitationPath), {
                    ...changes,
                })
            )
        }

        // If userId is present it means this user was re-invited
        // and there's invitation under users/{userId}/invitations/{organizationId} path.
        // We have to update that invitation data as well.
        if (userId) {
            await _changeUserInvitation(changes, userId, organizationId)
        }

        // update the Invitation
        dispatch(ReduxActions.invitationChanged(invitation))
    } catch (e) {
        displayError(e)
    }
}

const addInvitationChangedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        InvitationChangedCommand,
        CommandPlayer({
            CommandType: InvitationChangedCommand,
            Type: Invitation,
            collectionPath: organizationId => `/organizations/${organizationId}/invitations/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'organizationId',
        })
    )
}

export { InvitationChangedCommand, addInvitationChangedCommandSingleton }
