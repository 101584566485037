import { RadioGroupItem } from '@radix-ui/react-radio-group'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, FlexColumn, FlexRow, Link, RadioGroup, Text } from '../components-reusable/index.js'
import { BillingCycles, formatUsd, pricingMetrics, RangePlans } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { RoundButton } from './AdminViewSubscriptionBlock.js'
import StripeHelpers from '../stripe-helpers.js'

const standardBorder = '1px solid $neutral07'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100vw',
    left: 0,
    top: 0,
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'fixed',
    zIndex: 100,
    color: '$neutral04',
})

const StyledPrimaryLabel = styled(Box, {
    w: 'auto',
    c: '$neutral04',
    fs: 32,
    fw: 700,
})

const StyledRadioButton = styled(RadioGroupItem, {
    alignItems: 'center',
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: '$neutral05',
    cursor: 'pointer',
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 400,
    height: 38,
    justifyContent: 'space-between',
    minHeight: 38,
    transitionDuration: '0.4s',

    '&:hover': {
        color: '$neutral03',
        backgroundColor: '$primary02',
    },

    // handle "checked" value of Radio button
    "&[data-state='checked']": {
        backgroundColor: '$primary02',
        color: '$neutral04',
        border: '1px solid $primary04',
    },
})

const TitleRow = ({ onClose }) => (
    <FlexRow css={{ w: '100%' }}>
        <FlexColumn css={{ width: 'inherit' }}>
            <Button onClick={onClose} css={{ width: '70px', mb: '24px' }} variant="secondary" size="lg">
                <Text>Cancel</Text>
            </Button>
            <StyledPrimaryLabel>Upgrade to Range "Team" Plan</StyledPrimaryLabel>
            <Link css={{ textDecoration: 'underline', c: '$primary', fw: 400, fs: 14, pt: 8 }}>
                Learn about our plans
            </Link>
        </FlexColumn>
    </FlexRow>
)
const circledNumberCss = {
    fw: 700,
    fs: 20,
    mr: 12,
    justifyContent: 'center',
    w: 32,
    h: 32,
    bg: '$neutral09',
    b: standardBorder,
    br: 999,
    lineHeight: '29px',
}

const BillingCycleRow = ({ billingCycle, percentageDiscount, changeBillingCycle }) => {
    return (
        <FlexRow>
            <FlexRow css={circledNumberCss}>1</FlexRow>
            <FlexColumn>
                <Text css={{ fw: 700, fs: 20, pb: 8 }}>Billing Cycle</Text>
                <RadioGroup value={billingCycle} onValueChange={changeBillingCycle}>
                    <FlexRow css={{ ai: 'center', cursor: 'pointer' }}>
                        <StyledRadioButton value={BillingCycles.Annual}>
                            Annual
                            <Text
                                css={{
                                    b: '1px solid $green03',
                                    fw: 500,
                                    fs: 12,
                                    ml: 4,
                                    p: '2px 8px 2px 8px',
                                    br: 999,
                                    background: '$green02',
                                    color: '$neutral04',
                                }}
                            >
                                {percentageDiscount}% off
                            </Text>
                        </StyledRadioButton>
                    </FlexRow>
                    <StyledRadioButton value={BillingCycles.Monthly} css={{ ml: 16 }}>
                        Monthly
                    </StyledRadioButton>
                </RadioGroup>
            </FlexColumn>
        </FlexRow>
    )
}

const DecrementButton = ({ seatCount, onClick }) => {
    const cantDecrement = seatCount <= StripeHelpers.MIN_SEATS_COUNT
    const color = cantDecrement ? '$neutral06' : '$neutral04'
    const tooltip = cantDecrement ? 'Range Premium requires a minimum of 3 seats' : 'Fewer seats'
    return (
        <RoundButton
            tooltipText={tooltip}
            iconName="zoomOut"
            color={color}
            onClick={onClick}
            disabled={cantDecrement}
        />
    )
}

const IncrementButton = ({ onClick }) => <RoundButton tooltipText="More seats" iconName="zoomIn" onClick={onClick} />

const NumberOfSeatsRow = ({ seatCount, setSeatCount }) => {
    const increment = () => setSeatCount(seatCount + 1)
    const decrement = () => seatCount > 3 && setSeatCount(seatCount - 1)

    return (
        <FlexRow>
            <FlexRow css={circledNumberCss}>2</FlexRow>
            <FlexColumn>
                <Text css={{ fw: 700, fs: 20, pb: 4 }}>Choose Number of Seats</Text>
                <Text css={{ fw: 400, fs: 14, w: 455, color: '$neutral04' }}>
                    Paid seats may be assigned and reassigned to any member, and you can assign them Collaborator or
                    Admin roles. Guests are free users with limited permissions.
                </Text>
                <FlexRow css={{ ai: 'center', mt: 16, gap: 16 }}>
                    <DecrementButton seatCount={seatCount} onClick={decrement} />
                    <Text
                        css={{
                            fs: 32,
                            fw: 700,
                            w: 100,
                            p: '0 12px',
                            b: standardBorder,
                            display: 'inline-flex',
                            justifyContent: 'center',
                            br: 6,
                            backgroundColor: '$neutral09',
                        }}
                    >
                        {seatCount}
                    </Text>
                    <IncrementButton onClick={increment} />
                </FlexRow>
            </FlexColumn>
        </FlexRow>
    )
}

const OrderDetailsRow = ({ basePrice, billingCycle, discount, isDiscounted, percentageDiscount, totalAmountText }) => (
    <FlexRow css={{ bb: standardBorder, bt: standardBorder, mt: 10, mb: 10, pt: 10, pb: 10 }}>
        <FlexColumn>
            <Text css={{ fw: 700 }}>{`Range Team Plan - ${billingCycle}`}</Text>
            <Text css={{ fw: 500 }}>{totalAmountText}</Text>
            {isDiscounted && <Text css={{ fw: 500 }}>Annual discount ({percentageDiscount}%)</Text>}
        </FlexColumn>
        <FlexColumn css={{ ml: 'auto' }}>
            <Text>&nbsp;</Text>
            <Text css={{ textAlign: 'right', fw: 700 }}>{formatUsd(basePrice)}</Text>
            {isDiscounted && <Text css={{ textAlign: 'right', fw: 500 }}>{formatUsd(discount)}</Text>}
        </FlexColumn>
    </FlexRow>
)

const OrderSummary = ({
    basePrice,
    billingCycle,
    discount,
    isDiscounted,
    finalPrice,
    percentageDiscount,
    totalAmountText,
    onContinueToPayment,
}) => {
    return (
        <FlexColumn
            css={{
                b: standardBorder,
                p: 24,
                br: 6,
                minWidth: 500,
                fs: 14,
                backgroundColor: '$neutral09',
                height: 'fit-content',
            }}
        >
            <Text css={{ fw: 700 }}>Order Summary</Text>
            <OrderDetailsRow
                basePrice={basePrice}
                billingCycle={billingCycle}
                discount={discount}
                isDiscounted={isDiscounted}
                percentageDiscount={percentageDiscount}
                totalAmountText={totalAmountText}
            />
            <FlexRow css={{ mt: 10, mb: 10 }}>
                <Text css={{ fw: 700 }}>Total Due</Text>
                <Text css={{ textAlign: 'right', fw: 700, ml: 'auto' }}>{finalPrice}</Text>
            </FlexRow>
            <Button variant="primary" size="lg" css={{ ml: 'auto', mt: 8 }} onClick={onContinueToPayment}>
                Continue to Payment
            </Button>
        </FlexColumn>
    )
}

const AdminViewUpgradePage = ({ monthlyPrice, annualPrice, onClose, onContinueToPayment }) => {
    const continueToPayment = () => onContinueToPayment(billingCycle, seatCount)

    const [billingCycle, changeBillingCycle] = useState(BillingCycles.Annual)
    const [seatCount, setSeatCount] = useState(3)

    const metrics = pricingMetrics(RangePlans.Team, billingCycle, monthlyPrice, annualPrice, seatCount)
    const { isDiscounted, percentageDiscount, basePrice, discount, finalPrice } = metrics

    const totalAmountText =
        billingCycle === BillingCycles.Annual
            ? `${formatUsd(monthlyPrice)} x ${seatCount} seats x 12 months`
            : `${formatUsd(monthlyPrice)} x ${seatCount} seats`

    const finalPriceText = formatUsd(finalPrice)

    return (
        <StyledOuterContainer>
            <FlexColumn css={{ w: '80%', maxWidth: 1024, p: '80px 0 0 0px', gap: 32 }}>
                <TitleRow onClose={onClose} />
                <FlexRow css={{ gap: 40 }}>
                    <FlexColumn css={{ gap: 24 }}>
                        <BillingCycleRow
                            billingCycle={billingCycle}
                            percentageDiscount={percentageDiscount}
                            changeBillingCycle={changeBillingCycle}
                        />
                        <NumberOfSeatsRow seatCount={seatCount} setSeatCount={setSeatCount} />
                    </FlexColumn>
                    <OrderSummary
                        basePrice={basePrice}
                        billingCycle={billingCycle}
                        discount={discount}
                        isDiscounted={isDiscounted}
                        finalPrice={finalPriceText}
                        percentageDiscount={percentageDiscount}
                        totalAmountText={totalAmountText}
                        onContinueToPayment={continueToPayment}
                    />
                </FlexRow>
            </FlexColumn>
        </StyledOuterContainer>
    )
}

AdminViewUpgradePage.propTypes = {
    monthlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onContinueToPayment: PropTypes.func.isRequired,
}

export default AdminViewUpgradePage
