/*
 * See Readme
 */

import { ReduxSelectors } from '../../../redux/index.js'
import { changeSeatCount } from '../https-calls.js'

/*
 * Check if we have enough seats and update if more needed.
 * verifyAndUpdateSeatsCount :: (State, Integer) -> void
 */
const verifyAndUpdateSeatsCount = async (state, additionalSeatsNeeded) => {
    if (!additionalSeatsNeeded) return

    const organization = ReduxSelectors.selectedOrganization(state)
    const organizationParticipants = ReduxSelectors.organizationParticipantsAsArray(state)
    const organizationCurrentInvitations = ReduxSelectors.invitations(state).filter(
        invitation => !invitation.acceptedTimestamp
    ) // get only pending invitations

    const getCountByRole = (organizationRole, participants, invitations) =>
        participants.filter(el => el.organizationRole === organizationRole).length +
        invitations.filter(el => el.organizationRole === organizationRole).length

    const adminSeats = getCountByRole('Admin', organizationParticipants, organizationCurrentInvitations)
    const collaboratorSeats = getCountByRole('Collaborator', organizationParticipants, organizationCurrentInvitations)

    const currentSeatsCount = organization.seatCount
    const seatsCountAfterUpdating = adminSeats + collaboratorSeats + additionalSeatsNeeded

    // If we don't have enough seats for invited users, add them first
    if (currentSeatsCount < seatsCountAfterUpdating) {
        await changeSeatCount(organization.id, seatsCountAfterUpdating)
    }
}

export default verifyAndUpdateSeatsCount
