/*
 * A Button with variants:
 *
 * variant: secondary
 * sizes: sm, lg
 */
import React, { useEffect, useState } from 'react'
import { styled } from '../range-theme/index.js'
import { LoadingRingAnimation } from '../components-reusable/index.js'

const StyledButton = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer',
    position: 'relative',

    backgroundColor: '$$backgroundColor',

    '&[data-isloading="true"]': {
        '& > :not(.loading-ring)': {
            opacity: 0,
        },
    },

    '&:disabled, &[data-isloading="true"]': {
        backgroundColor: '$$backgroundColor',
        cursor: 'not-allowed',
        color: '$$disabledColor',
        path: {
            fill: '$$disabledColor',
        },
    },

    '&:hover:enabled': {
        backgroundColor: '$$hoverBackgroundColor',
        color: '$$hoverColor',
    },

    variants: {
        variant: {
            primary: {
                $$backgroundColor: 'var(--colors-primary04)',
                $$hoverBackgroundColor: 'var(--colors-primary06)',
                $$hoverColor: 'white',
                $$disabledColor: '#ffffff60',
                boxShadow: '0px 0px 0px #5D81FF50',

                color: '$white',
                border: 'none',
                transitionDuration: '0.4s',

                '&:hover': {
                    boxShadow: '6px 6px 2px #5D81FF40',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary06)',
                    color: '$white',
                    boxShadow: 'none',
                },
            },
            destructive: {
                $$backgroundColor: 'var(--colors-red03)',
                $$hoverBackgroundColor: 'var(--colors-red04)',
                $$hoverColor: 'white',
                $$disabledColor: '#FFFFFF50',

                color: '#FFFFFF',
                border: 'none',
                padding: '10px',
                transitionDuration: '0.4s',
                boxShadow: '0px 0px 0px #F13D1520',

                '&:hover:enabled': {
                    boxShadow: '6px 6px 2px #F13D1530',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-red04)',
                    color: '$white',
                    boxShadow: 'none',
                },
            },
            destructiveSecondary: {
                $$backgroundColor: 'var(--colors-neutral09)',
                $$hoverBackgroundColor: 'var(--colors-neutral09)',
                $$hoverColor: 'var(--colors-neutral04)',
                $$disabledColor: '#F13D1550',

                color: 'var(--colors-red03)',
                border: '1px solid var(--colors-neutral07)',
                padding: '10px',
                transitionDuration: '0.4s',

                boxShadow: '0px 0px 0px #F13D1550',

                '&:hover:enabled': {
                    border: '1px solid var(--colors-red02)',
                    boxShadow: '6px 6px 2px #F13D1520',
                    color: 'var(--colors-red03)',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-red04)',
                    color: '$white',
                    boxShadow: 'none',
                },
            },
            secondary: {
                $$hoverBackgroundColor: 'var(--colors-primary02)',
                $$backgroundColor: 'var(--colors-neutral09)',
                $$hoverColor: 'var(--colors-neutral04)',
                $$disabledColor: 'var(--colors-neutral06)',

                border: '1px solid var(--colors-neutral07)',
                color: 'var(--colors-neutral05)',
                padding: '10px',
                transitionDuration: '0.4s',
                boxShadow: '0px 0px 0px #5D81FF50',

                '&:hover:enabled': {
                    border: '1px solid var(--colors-primary03)',
                    boxShadow: '6px 6px 2px #8B8F9D20',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary03)',
                    color: 'var(--colors-neutral04)',
                    boxShadow: 'none',
                },
            },
            outline: {
                $$hoverBackgroundColor: 'var(--colors-primary02)',
                $$backgroundColor: 'var(--colors-neutral10)',
                $$hoverColor: 'var(--colors-neutral04)',
                $$disabledColor: 'var(--colors-neutral06)',

                border: '1px solid var(--colors-neutral07)',
                color: 'var(--colors-neutral05)',
                padding: '10px',
                transitionDuration: '0.4s',

                '&:hover:enabled': {
                    border: '1px solid var(--colors-primary03)',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary03)',
                    color: 'var(--colors-neutral04)',
                },
            },
            manage: {
                $$hoverBackgroundColor: 'var(--colors-primary02)',
                $$hoverColor: 'var(--colors-neutral04)',
                $$disabledColor: 'var(--colors-neutral06)',

                border: '1px solid var(--colors-neutral07)',
                color: 'var(--colors-neutral04)',
                padding: '10px',
                transitionDuration: '0.4s',

                '&:hover:enabled': {
                    border: '1px solid var(--colors-primary03)',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary03)',
                    color: 'var(--colors-neutral04)',
                },
            },
            'dropdown-trigger': {
                all: 'unset',
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                borderRadius: '6px',
                border: '1px solid transparent',
                transitionDuration: '0.2s',

                $$hoverColor: 'var(--colors-neutral04)',
                $$hoverBackgroundColor: 'var(--colors-primary02)',

                '&[aria-expanded="true"]': {
                    backgroundColor: 'var(--colors-neutral07)',
                },
            },
            'dropdown-menuitem': {
                border: 'none',
                color: '$neutral04',
                justifyContent: 'normal',

                $$hoverColor: 'var(--colors-neutral04)',
                $$hoverBackgroundColor: 'var(--colors-primary02)',
            },
            'dropdown-annotation-tools': {
                border: 'none',
                color: 'var(--colors-neutral04)',
                background: 'var(--colors-neutral10)',

                borderRadius: '0 6px 6px 0',
                borderRight: '1px solid $neutral07',

                $$hoverColor: 'var(--colors-neutral04)',
                $$hoverBackgroundColor: 'var(--colors-neutral08)',
            },
            'dropdown-annotation-tools-delete': {
                color: 'var(--colors-red03)',
                w: 40,
                h: 40,
                borderRadius: 6,
                border: '1px solid $neutral07',

                $$backgroundColor: 'var(--colors-neutral10)',
                $$hoverColor: 'var(--colors-red04)',
                $$hoverBackgroundColor: 'var(--colors-neutral08)',
            },
            'dropdown-menuitem-navigator': {
                border: 'none',
                color: '$neutral05',
                justifyContent: 'normal',
                outlineColor: 'transparent',
                outlineStyle: 'inset',
                outlineOffset: '-1px',
                outlineWidth: '1px',
                transition: 'all 0.4s ease, outline 0s linear',

                $$hoverColor: 'var(--colors-neutral03)',
                $$hoverBackgroundColor: 'var(--colors-primary02)',

                "&[data-selected='true']": {
                    backgroundColor: 'var(--colors-neutral07)',
                    color: '$neutral04',
                },

                '&:hover': {
                    outline: 'none',
                },

                '&:focus': {
                    outlineColor: '$primary04',
                    backgroundColor: '$$hoverBackgroundColor',
                    color: '$$hoverColor',
                },
            },
            'dropdown-menuitem-destructive': {
                $$hoverBackgroundColor: 'var(--colors-red03)',
                $$hoverColor: 'white',
                $$disabledColor: 'var(--colors-red02)',

                color: '$red03',
                border: 'none',
                justifyContent: 'normal',
                transitionDuration: '0.4s',

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-red04)',
                    color: '$white',
                },
            },
            // for a button that looks like a link, because uses onClick rather than href
            'no-appearance': {
                $$backgroundColor: 'none',
                $$hoverBackgroundColor: 'none',
                $$hoverColor: 'var(--colors-primary06)',
                color: 'var(--colors-primary04)',
                boxShadow: 'none',
                border: 'none',
                padding: 0,
                display: 'inline-block',
            },
            followers: {
                $$backgroundColor: 'none',
                $$hoverBackgroundColor: 'var(--colors-neutral09)',
                boxShadow: 'none',
                border: 'none',
                borderRadius: '6px',

                "&[aria-expanded='true']": {
                    backgroundColor: '$$hoverBackgroundColor',
                },

                '&:focus': {
                    backgroundColor: '$$hoverBackgroundColor',
                },

                '&[data-isloading="true"]': {
                    $$backgroundColor: 'var(--colors-neutral09)',
                },
            },
            neutral: {
                $$backgroundColor: 'var(--colors-neutral09)',
                $$hoverBackgroundColor: 'var(--colors-neutral07)',
                $$hoverColor: 'var(--colors-neutral04)',
                borderRadius: '6px',
                color: 'var(--colors-neutral04)',
                border: '1px solid $neutral07',
            },
            accentPink: {
                $$backgroundColor: 'var(--colors-pink03)',
                $$hoverBackgroundColor: 'var(--colors-pink04)',
                $$hoverColor: 'white',
                borderRadius: '6px',
                color: 'white',
                border: 'none',
            },
        },
        size: {
            sm: {
                fontSize: '13px',
                borderRadius: '4px',
                maxHeight: '32px',
                height: '32px',
            },
            md: {
                fontSize: '14px',
                borderRadius: '6px',
                maxHeight: '32px',
                height: '32px',
            },
            lg: {
                fontSize: '14px',
                borderRadius: '6px',
                maxHeight: '40px',
                height: '40px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            xl: {
                fontSize: '14px',
                borderRadius: '6px',
                maxHeight: '48px',
                height: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
    },
    compoundVariants: [
        {
            variant: 'primary',
            size: 'sm',
            css: {
                padding: '4px 6px 4px 6px',
                maxHeight: '24px',
                fontSize: '11px',
            },
        },
        {
            variant: 'primary',
            size: 'md',
            css: {
                padding: '6px 16px 7px 16px',
                maxHeight: '32px',
                fontSize: '14px',
            },
        },
        {
            variant: 'primary',
            size: 'lg',
            css: {
                padding: '10px 16px 11px 16px',
                maxHeight: '40px',
                fontSize: '14px',
            },
        },
        {
            variant: 'dropdown-trigger',
            size: 'lg',
            css: {
                minHeight: '40px',
                minWidth: '40px',
            },
        },
    ],
    defaultVariants: {},
})

/**
 * A button component that supports loading states for asynchronous actions.
 *
 * @param {React.ReactNode} children - The content to be displayed inside the button.
 * @param {boolean} disabled - Whether the button is disabled.
 * @param {boolean} isLoading - Initial loading state of the button.
 * @param {string} size - The size of the button.
 * @param {boolean} withLoadingState - Whether to show loading state during async actions. In your onClick function you have to either return a Promise, or make this function async with awaits.
 * @param {function} onClick - The function to be called on button click.
 * @param {Object} ...rest - Any other props to be passed to the button.
 * @param {React.Ref} ref - The ref to be forwarded to the button element.
 *
 * @returns {React.ReactElement} The rendered button component.
 */
const Button = React.forwardRef(
    ({ children, disabled, isLoading: initialIsLoading, size, withLoadingState, onClick, ...rest }, ref) => {
        const [isLoading, setIsLoading] = useState(initialIsLoading)

        useEffect(() => {
            setIsLoading(initialIsLoading)
        }, [initialIsLoading])

        const handleOnClick = async e => {
            if (!onClick) return
            if (!withLoadingState) return onClick(e)

            setIsLoading(true)

            try {
                await onClick(e)
            } finally {
                setIsLoading(false)
            }
        }

        return (
            <StyledButton
                ref={ref}
                onClick={handleOnClick}
                disabled={disabled || isLoading}
                data-isloading={isLoading}
                size={size}
                {...rest}
            >
                {children}
                {isLoading && <LoadingRingAnimation css={{ position: 'absolute' }} size={size} />}
            </StyledButton>
        )
    }
)

export default Button
