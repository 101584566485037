import { DateFormat, Geometry, TimeFormat } from '@range.io/basic-types'
import { formatDistance } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Box, Button, FlexColumn, FlexRow, Icon, Text } from '../components-reusable'
import { useBooleanState, useOnClickOutside } from '../components-reusable/hooks'
import { styled } from '../range-theme'
import { useSelector } from 'react-redux'
import { ReduxSelectors } from '../redux'
import { InformationDataRow } from './CollaborationAdditionalInformationPanel'

const StyledHoverListContainer = styled(FlexRow, {
    position: 'absolute',
    left: 0,
    background: '$neutral04',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    padding: '8px',
    mb: '4px',
    mt: '4px',
    width: 'max-content',
    ai: 'center',
    gap: '8px',
    zIndex: 1,
    color: '$neutral07',
    fs: 14,
    fw: 500,
})

const StyledPillWrapper = styled(FlexRow, {
    ai: 'center',
    height: '28px',
    width: '100%',
    b: '1px solid $neutral07',
    background: '$neutral09',
    color: '$neutral04',
    cursor: 'pointer',
    br: 999,
    fs: 14,
    fw: 500,
    pl: '8px',
    pr: '10px',
    gap: '6px',
    transitionDuration: '0.4s',
    '&:hover': { backgroundColor: '$primary02', border: '1px solid $primary03' },

    variants: {
        overdue: {
            true: {
                border: '1px solid $red03',
                color: '$red03',
                '&:hover': { backgroundColor: '$red01', border: '1px solid $red03' },
            },
        },
        noEllipsis: {
            true: {
                minWidth: 'fit-content',
            },
        },
    },
})

const BasePill = ({ hoverLabel, icon, label, ...pillProps }) => {
    const { set: show, unset: hide, get: isVisible } = useBooleanState(false)
    return (
        <Box css={{ position: 'relative' }}>
            <StyledPillWrapper onMouseOver={show} onMouseOut={hide} {...pillProps}>
                <Icon iconSize="16px" name={icon} css={{ minWidth: '16px' }} />
                <Text css={{ maxWidth: 'inherit', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {label}
                </Text>
            </StyledPillWrapper>
            {isVisible() && <StyledHoverListContainer>{hoverLabel}</StyledHoverListContainer>}
        </Box>
    )
}

export const CanvasSheetPill = ({ sheetLabel, onClick }) => (
    <BasePill
        data-cy="list-view-canvas-pill"
        onClick={onClick}
        label={sheetLabel}
        hoverLabel="Task Location"
        icon="levels"
    />
)

export const LastUpdatedPill = ({ lastUpdated }) => {
    const text = formatDistance(lastUpdated, Date.now(), { addSuffix: true })
    return <BasePill label={text} hoverLabel={`Last updated ${text}`} icon="clock" />
}

LastUpdatedPill.propTypes = {
    lastUpdated: PropTypes.object.isRequired, // Date object
}

export const DueDatePill = ({ dueDate, isCompleted, isArchived, ...restProps }) => {
    const isOverdue = dueDate < new Date() && !isCompleted && !isArchived // display as overdue only when task is not completed and not archived
    return (
        <BasePill
            {...restProps}
            label={DateFormat.primary(dueDate)}
            hoverLabel={formatDistance(dueDate, new Date(), { addSuffix: true })}
            icon="calendar"
            overdue={isOverdue}
            noEllipsis={true}
            data-cy="list-view-due-date"
        />
    )
}

DueDatePill.propTypes = {
    dueDate: PropTypes.object.isRequired, // Date object
}

const StyledLabelWrapper = styled(FlexRow, {
    ai: 'center',
    height: '28px',
    minWidth: 'fit-content',
    cursor: 'pointer',
    fs: 14,
    fw: 500,
    color: '$neutral04',
})

export const CreatedOnLabel = ({ createdOn }) => {
    const { set: show, unset: hide, get: isVisible } = useBooleanState()
    const text = DateFormat.primary(createdOn)
    return (
        <Box css={{ position: 'relative', minWidth: 'fit-content' }}>
            <StyledLabelWrapper onMouseOver={show} onMouseOut={hide}>
                <Text css={{ minWidth: 'fit-content' }}>{text}</Text>
            </StyledLabelWrapper>
            {isVisible() && <StyledHoverListContainer>Created on</StyledHoverListContainer>}
        </Box>
    )
}

CreatedOnLabel.propTypes = {
    createdOn: PropTypes.object.isRequired, // Date object
}

const StyledLastUpdatedLabel = styled(Text, {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '$neutral05',
    justifyContent: 'start',
    whiteSpace: 'nowrap',
    paddingTop: '8px',
    paddingBottom: '6px',
})

export const LastUpdatedLabel = ({ mostRecentUpdateDate }) => {
    const { set: show, unset: hide, get: isVisible } = useBooleanState()
    if (!mostRecentUpdateDate) return
    return (
        <Box
            data-cy="list-view-update-date"
            sx={{ display: { xs: 'none', md: 'none' } }}
            css={{ position: 'relative', minWidth: 'fit-content' }}
        >
            <StyledLabelWrapper onMouseOver={show} onMouseOut={hide}>
                {mostRecentUpdateDate && (
                    <StyledLastUpdatedLabel>&nbsp;({TimeFormat.tertiary(mostRecentUpdateDate)})</StyledLastUpdatedLabel>
                )}
            </StyledLabelWrapper>
            {isVisible() && <StyledHoverListContainer>Last Updated</StyledHoverListContainer>}
        </Box>
    )
}

LastUpdatedLabel.propTypes = {
    mostRecentUpdateDate: PropTypes.object, // Date object
}

const StyledInfoContentWrapper = styled(FlexColumn, {
    position: 'absolute',
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    padding: '10px 12px 12px 12px',
    color: '$neutral04',
    width: '264px',
    zIndex: 1,
    left: '-240px',
    marginTop: '6px',
})

export const AdditionalInformation = ({ canvasId, geometry }) => {
    const { set: showHoverLabel, unset: hideHoverLabel, get: isHoverLabelVisible } = useBooleanState(false)
    const { set: showInfoContent, unset: hideInfoContent, get: isInfoContentVisible } = useBooleanState(false)
    const { canvasSource } = useSelector(ReduxSelectors.canvasAndFirstSourceForId(canvasId))
    const infoContentRef = useRef()
    const iconRef = useRef()

    useOnClickOutside([infoContentRef, iconRef], () => hideInfoContent())

    // Add useEffect to handle ESC key press
    React.useEffect(() => {
        const handleEscKey = event => {
            if (event.key === 'Escape' && isInfoContentVisible()) {
                hideInfoContent()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [hideInfoContent, isInfoContentVisible])

    const hasAdditionalInfo =
        canvasSource?.isGeolocated || canvasSource.type === 'mapbox' || canvasSource.type === 'dronedeploy'

    const geometryDataFormatted = Geometry.getFormattedForDisplay(geometry)

    const handleCopyToClipboard = async (value, event) => {
        event.stopPropagation()
        await navigator.clipboard.writeText(value)
    }

    const getBulkCopyText = () => {
        const position = `Latitude: ${geometryDataFormatted.lat}, Longitude: ${geometryDataFormatted.lng}`
        if (geometryDataFormatted?.elevationMetres) {
            return `${position}, Elevation: ${geometryDataFormatted.elevationMetres}m / ${geometryDataFormatted.elevationFeet}ft`
        }
        return position
    }

    const handleClick = event => {
        if (hasAdditionalInfo) {
            event.stopPropagation()
            isInfoContentVisible() ? hideInfoContent() : showInfoContent()
        }
    }

    const hoverLabel = hasAdditionalInfo
        ? 'Show Lat / Long / Elevation data'
        : 'Lat / Long / Elevation data not available'
    return (
        <Box css={{ position: 'relative' }}>
            <StyledPillWrapper
                onMouseOver={showHoverLabel}
                onMouseOut={hideHoverLabel}
                onClick={handleClick}
                ref={iconRef}
                css={{ padding: '5px', color: hasAdditionalInfo ? '$neutral04' : '$neutral06' }}
            >
                <Icon iconSize="16px" name="globe" css={{ minWidth: '16px' }} />
            </StyledPillWrapper>
            {isHoverLabelVisible() && (
                <StyledHoverListContainer css={{ left: hasAdditionalInfo ? -90 : -130 }}>
                    {hoverLabel}
                </StyledHoverListContainer>
            )}
            {isInfoContentVisible() && (
                <StyledInfoContentWrapper ref={infoContentRef}>
                    <InformationDataRow
                        label="Latitude:"
                        value={geometryDataFormatted.lat}
                        onClick={handleCopyToClipboard}
                    />
                    <InformationDataRow
                        label="Longitude:"
                        value={geometryDataFormatted.lng}
                        onClick={handleCopyToClipboard}
                    />
                    {geometry?.elevation && (
                        <>
                            <InformationDataRow
                                label="Elevation:"
                                value={`${geometryDataFormatted.elevationMetres}m`}
                                onClick={handleCopyToClipboard}
                            />
                            <InformationDataRow
                                label=""
                                value={`${geometryDataFormatted.elevationFeet}ft`}
                                onClick={handleCopyToClipboard}
                            />
                        </>
                    )}
                    <Button
                        onClick={event => handleCopyToClipboard(getBulkCopyText(), event)}
                        css={{ marginTop: '8px' }}
                        variant="primary"
                        size="lg"
                    >
                        <Icon name="copyToClipboardLeft" iconSize="18px" />
                        <Text>Copy to Clipboard</Text>
                    </Button>
                </StyledInfoContentWrapper>
            )}
        </Box>
    )
}
