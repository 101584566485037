/*
 * Per tile/pixel computations
 *
 * Assume:
 *
 * - we arbitrarily adopt Mapbox's convention that a "tile" is 512 x 512 pixels
 * - we arbitrarily adopt the CSS definition of a pixel, which is a measure of *length* 1/96 inch long
 * - at zoom level 0, the whole Earth can be viewed in 1 tile and that at each increasing zoom
 *      there are twice as many tiles in both directions, so that zoom 1 has 4 tiles:
 *      2 wide and 2 high, each of which covers a 1/4 of the Earth in 512 x 512 pixels
 *
 * And these are true:
 *
 * - the circumference of the Earth at the Equator is (roughly) 40,075 kilometers
 * - the length of a "small circle" connecting all the points at a given latitude depends on the latitude
 *
 * Then:
 *
 * - the number of tiles to cover a small circle of the earth doubles at every zoom
 *   so at zoom z: 2 ^ z tiles are needed are needed to cover any small circle
 * - the number of meters in a tile is: (length of the small circle at the tile's latitude)  / (2 ^ z)
 * - the number of metersPerPixel = number of meters per tile / 512 pixels per tile
 * - a tile will be 5 1/3 inches wide (512 pixels / 96 pixels / inch)
 *
 * "Ground-size" Tiles
 *
 * For a given latitude there is some zoom level at which the space taken up on the screen is the same
 * as that on the ground. That is, there is some zoom where the 5 1/3 inches of tile would show a part
 * of the Earth 5 1/3 inches wide. At the equator, this is about 28.14; at latitude 40 it's more like 27.76.
 * These are really approximate values since a screen pixel is basically never precisely 1/96th of an inch.
 *
 * At 0 degrees latitude
 *
 *      40075000 meter * 39.37 in  * 96 pixels  *     1     = 512.1 pixels
 *                       --------    ---------    ---------
 *                        meter       inch        2 ^ 28.14
 *
 * At 40 degrees latitude
 *
 *      30699244 meter * 39.37 in  * 96 pixels  *     1     = 510.5 pixels
 *                       --------    ---------    ---------
 *                        meter       inch        2 ^ 27.76
 *
 * Types
 *
 *  Degrees = Number [-90..90]
 *  Zoom    = Number [0..30]
 *  Meters  = Number
 *  LngLat  = [Degrees, Degrees]
 *
 *
 * @see https://docs.mapbox.com/help/glossary/zoom-level/
 */

// equatorial mean radius of Earth (in meters)

const R = 6378137

const squared = x => x * x

// degrees to radians :: Degrees -> Number
const degreesToRadians = x => (x * Math.PI) / 180.0

/*
 * Great Circle distance between two LngLats (approximated)
 * @sig greatCircleDistance :: (LngLat, LngLat) -> Meters
 */
const greatCircleDistance = (lngLat1, lngLat2) => {
    const haversine = x => squared(Math.sin(x / 2))

    const lng1 = degreesToRadians(lngLat1[0])
    const lng2 = degreesToRadians(lngLat2[0])
    const lat1 = degreesToRadians(lngLat1[1])
    const lat2 = degreesToRadians(lngLat2[1])

    const ht = haversine(lat2 - lat1) + Math.cos(lat1) * Math.cos(lat2) * haversine(lng2 - lng1)
    return 2 * R * Math.asin(Math.sqrt(ht))
}

/*
 * Given a latitude in degrees, return the number of meters for each degree of longitude
 * @sig metersPerDegreeOfLngAtLatitude :: Degrees -> Meters
 */
const metersPerDegreeOfLngAtLatitude = lat => {
    const metersPerMillionthDegreeOfLng = greatCircleDistance([0, lat], [0.000001, lat])
    return metersPerMillionthDegreeOfLng * 1000000
}

/*
 * Given a latitude in degrees, return the number of meters for each degree of latitude
 * @sig metersPerDegreeOfLatAtLatitude :: Degrees -> Meters
 */
const metersPerDegreeOfLatAtLatitude = lat => {
    const metersPerMillionthDegreeOfLat = greatCircleDistance([0, lat - 0.0000005], [0, lat + 0.0000005])
    return metersPerMillionthDegreeOfLat * 1000000
}

/*
 * Return *just* the (orthogonal) horizontal distance in meters between two LatLngs
 * @sig horizontalDistanceInMeters :: (LngLat, LngLat) -> Number
 */
const horizontalDistanceInMeters = (p1, p2) => {
    const metersPerLng = metersPerDegreeOfLngAtLatitude(p1.lat)
    const distanceLng = p2.lng - p1.lng
    return distanceLng * metersPerLng
}

/*
 * Return *just* the (orthogonal) vertical distance in meters between two LatLngs
 * @sig verticalDistanceInMeters :: (LngLat, LngLat) -> Number
 */
const verticalDistanceInMeters = (p1, p2) => {
    const metersPerLat = metersPerDegreeOfLatAtLatitude(p1.lat)
    const distanceLat = p2.lat - p1.lat
    return distanceLat * metersPerLat
}

/*
 * Return the width and height in meters of a box defined by three corners
 * @sig lngLatBoundsInMetersAndFeet = (LngLat, LngLat, LngLat) => [Number, Number]
 */
const lngLatBoundsInMeters = (northWest, northEast, southWest) => [
    horizontalDistanceInMeters(northWest, northEast),
    verticalDistanceInMeters(southWest, northWest),
]

/*
 * Return a Bounds from a center and a width and height in meters
 * @sig boundsFromCenterAndSize = (LngLat, Number, Number) => Bounds
 *   Bounds = { north; LngLat, east: LngLat, south: LngLat, west: LngLat }
 */
const boundsFromCenterAndSize = (center, width, height) => {
    const metersPerLat = metersPerDegreeOfLatAtLatitude(center.lat)
    const metersPerLng = metersPerDegreeOfLngAtLatitude(center.lat)
    const widthInLng = width / metersPerLng
    const heightInLat = height / metersPerLat

    const north = center.lat + heightInLat / 2
    const south = center.lat - heightInLat / 2
    const east = center.lng + widthInLng / 2
    const west = center.lng - widthInLng / 2

    return { north, south, east, west }
}

export {
    degreesToRadians,
    horizontalDistanceInMeters,
    verticalDistanceInMeters,
    lngLatBoundsInMeters,
    boundsFromCenterAndSize,
}
