import { taggedSum } from '@range.io/functional'
import CommandPlayer from './command-player'
import { Participant } from '@range.io/basic-types'
import { ReduxActions } from '../../redux'

const ProjectParticipantRemovedCommand = taggedSum('ProjectParticipantRemovedCommand', {
    Inbound: { participant: 'Participant' },
})

/*
 * A ProjectParticipantRemovedCommand.Inbound has arrived from Firestore
 * Update the participant for current project. It's always from regular -> suspended
 */
const runInboundCommand = (resources, command) => {
    resources.dispatch(
        ReduxActions.toggleProjectsForUser({
            projectIds: [resources.projectId],
            organizationParticipant: command.participant,
            isSuspended: true,
        })
    )
}

const addProjectParticipantRemovedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectParticipantRemovedCommand,
        CommandPlayer({
            CommandType: ProjectParticipantRemovedCommand,
            Type: Participant,
            collectionPath: projectId => `/projects/${projectId}/participants`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'removed',
            resourceKey: 'projectId',
        })
    )
}

export { addProjectParticipantRemovedCommandSingleton }
