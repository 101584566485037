/*
 * See Readme
 */

import { Participant } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { addProjectParticipant, removeProjectParticipant } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// ParticipantAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const ProjectParticipantAddedCommand = taggedSum('ProjectParticipantAddedCommand', {
    Inbound: { participant: 'Participant' },
    Outbound: {
        projectIds: '[StringTypes.Id]?',
        organizationParticipant: 'Participant',
        isSuspended: 'Boolean',
    },
})

/*
 * A ProjectParticipantAddedCommand.Inbound has arrived from Firestore
 * Update the participant for current project. It's always from suspended -> regular
 */
const runInboundCommand = (resources, command) => {
    const { dispatch, projectId } = resources
    const { participant } = command
    dispatch(
        ReduxActions.toggleProjectsForUser({
            projectIds: [projectId],
            organizationParticipant: participant,
            isSuspended: false,
        })
    )
}

/*
 * A ParticipantAddedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the participant)
 */
const runOutboundCommand = async (resources, command) => {
    const { projectIds, organizationParticipant, isSuspended } = command
    const { dispatch, displayError } = resources

    try {
        const userId = organizationParticipant.id

        // change isSuspended immediately to give the user feedback, because it can take a long time to run
        dispatch(ReduxActions.toggleProjectsForUser({ projectIds, organizationParticipant, isSuspended }))
        return isSuspended
            ? await removeProjectParticipant(projectIds, userId)
            : await addProjectParticipant(projectIds, userId)
    } catch (e) {
        // it didn't work, reset isSuspended to what it was originally
        dispatch(ReduxActions.toggleProjectsForUser({ projectIds, organizationParticipant, isSuspended: !isSuspended }))
        displayError(e)
    }
}

const addProjectParticipantAddedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectParticipantAddedCommand,
        CommandPlayer({
            CommandType: ProjectParticipantAddedCommand,
            Type: Participant,
            collectionPath: projectId => `/projects/${projectId}/participants/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'projectId',
        })
    )
}

export { addProjectParticipantAddedCommandSingleton, ProjectParticipantAddedCommand }
