import { CanvasSource } from '@range.io/basic-types'
import { httpsCallable } from 'firebase/functions'
import { getUtmAttributionParams } from '../../segment/segment.js'
import { functions } from '../configure-environment/config-local.js'

/*
 * Wrap a Firebase Function call (httpsCallable) and return the result
 * @sig httpsCall :: (String, {k:v}) -> Promise {k:v}
 */
const httpsCall = async (functionName, inputData) => {
    const callable = httpsCallable(functions, functionName)

    // (mostly for Cypress testing, where the functions are only written on an as-needed basis)
    if (!callable) throw new Error(`Internal error: didn't find function '${functionName}'`)

    const { data } = await callable(inputData)
    return data
}

/*
 * Call to remote createCollaboration; returns nothing. Don't create Canvas, Geometry, Feature or Collaboration
 * All parameters are required except, that statusId must be set only for tasks
 */
const createCollaboration = async ({
    projectId,
    canvasId,
    geometryId,
    featureId,
    collaborationId,
    coordinates,
    statusId,
    name = '',
    description,
    assigneeId,
    dueDateTimestamp,
    tags,
    followers,
    uploads,
}) => {
    // following values can't be null, pass them if they have defined value, otherwise skip
    const dataWithDefinedValues = {
        tags,
        followers,
    }

    // We have to go through each argument and skip passing it down if it's undefined.
    // Otherwise it gets turned into null.
    const notNullData = {}
    Object.keys(dataWithDefinedValues).forEach(prop => {
        if (dataWithDefinedValues[prop] !== undefined) notNullData[prop] = dataWithDefinedValues[prop]
    })

    return await httpsCall('createCollaboration', {
        projectId,
        canvasId,
        geometryId,
        featureId,
        collaborationId,
        coordinates,
        statusId,
        name,
        description,
        assigneeId,
        dueDateTimestamp,
        ...notNullData,
    })
}
/*
 * Call to remote createComment; returns nothing.
 */
const createComment = async ({ projectId, commentId, parentType, parentId, text, isNote }) =>
    await httpsCall('createComment', {
        projectId,
        commentId,
        parentType,
        parentId,
        text,
        isNote,
    })

/*
 * Call to remote createOrganization; returns id of new Organization
 * @sig createOrganization :: (String, String) -> Id
 */
const createOrganization = async (name, industry, organizationSize) => {
    const utmAttribution = getUtmAttributionParams()
    const { organizationId } = await httpsCall('createOrganization', {
        name,
        industry,
        organizationSize,
        utmAttribution,
    })
    return organizationId
}

/*
 * Call to remote createOrganization; returns id of new Organization
 * @sig createOrganization :: (String, String) -> Id
 */
const createProject = async ({
    organizationId,
    projectId,
    projectType,
    name,
    center,
    address,
    canvasOrder,
    description,
    tagSource,
    canvases,
    canvasSources,
}) => {
    await httpsCall('createProject', {
        organizationId,
        projectId,
        projectType,
        name,
        center,
        address,
        canvasOrder,
        description,
        tagSource,
        canvases,
        canvasSources: canvasSources.map(CanvasSource.toFirebase),
    })
    return projectId
}

/*
 * Call to remote createUpload; returns nothing.
 */
const createUpload = async ({
    // required:
    projectId,
    collaborationId,
    uploadId,
    fileType,
    fileSize,
    imageHeightToWidth,
    // not required:
    correlationId,
    name,
    description,
    tags,
    annotations,
}) => {
    await httpsCall('createUpload', {
        projectId,
        collaborationId,
        uploadId,
        fileType,
        fileSize,
        imageHeightToWidth,
        correlationId,
        name,
        description,
        tags,
        annotations,
    })
}

/*
 * Call to remote deleteCanvas
 * @sig deleteCanvas :: (Id, Id) -> Promise void
 */
const deleteCanvas = async (projectId, canvasId) => await httpsCall('deleteCanvas', { projectId, canvasId })

/*
 * Call to remote deleteCollaboration
 * @sig deleteCollaboration :: (Id, Id) -> Promise void
 */
const deleteCollaboration = async (projectId, collaborationId) =>
    await httpsCall('deleteCollaboration', { projectId, collaborationId })

/*
 * Call to remote deleteComment
 * @sig deleteComment :: (Id, Id) -> Promise void
 */
const deleteComment = async (projectId, commentId) => await httpsCall('deleteComment', { projectId, commentId })

/*
 * Call to remote deleteProject
 * @sig deleteProject :: (Id) -> Promise void
 */
const deleteProject = async projectId => await httpsCall('deleteProject', { projectId })

/*
 * Call to remote deleteUpload
 * @sig deleteUpload :: (Id, Id) -> Promise void
 */
const deleteUpload = async (projectId, uploadId) => await httpsCall('deleteUpload', { projectId, uploadId })

/*
 * Call to remote inviteToOrganization
 * @sig inviteToOrganization :: (Id, Invitation) -> Promise void
 */
const inviteToOrganization = async (organizationId, invitation) => {
    const { inviteeEmail, organizationRole, projectIds } = invitation
    await httpsCall('inviteToOrganization', { organizationId, inviteeEmail, organizationRole, projectIds })
}

/*
 * Call to remote resendInvitation
 * @sig resendInvitation :: (Id, Id) -> Promise void
 */
const resendInvitation = async (organizationId, invitationId) =>
    await httpsCall('resendInvitation', { organizationId, invitationId })

/*
 * Call to remote acceptInvitation
 * @sig acceptInvitation :: Id -> Promise void
 */
const acceptInvitation = async invitationId => {
    const utmAttribution = getUtmAttributionParams()
    await httpsCall('acceptInvitation', { invitationId, utmAttribution })
}

/*
 * Call to remote removeInvitation
 * @sig removeInvitation :: (Id, Id) -> Promise void
 */
const removeInvitation = async (organizationId, invitationId) =>
    await httpsCall('removeInvitation', { organizationId, invitationId })

/*
 * Call to remote addProjectParticipant; users/{userId} and projects/{projectId} must exist
 * @sig addProjectParticipant :: { [Id], Id } -> Promise void
 */
const addProjectParticipant = async (projectIds, userId) =>
    await httpsCall('addProjectParticipant', { projectIds, userId })

/*
 * Call to remote removeProjectParticipant; users/{userId} and projects/{projectId} must exist
 * @sig removeProjectParticipant :: { [Id], Id } -> Promise void
 */
const removeProjectParticipant = async (projectIds, userId) =>
    await httpsCall('removeProjectParticipant', { projectIds, userId })

/*
 * Call to remote removeOrganizationParticipant; users/{userId} and organization/{organizationId} must exist
 * @sig removeOrganizationParticipant :: { Id, Id } -> Promise void
 */
const removeOrganizationParticipant = async (organizationId, userId) =>
    await httpsCall('removeOrganizationParticipant', { organizationId, userId })

/*
 * Call to remote removeSelfFromOrganizationParticipants; users/{userId} and organization/{organizationId} must exist
 * @sig removeSelfFromOrganizationParticipants :: { Id, Id } -> Promise void
 */
const removeSelfFromOrganizationParticipants = async (organizationId, userId) =>
    await httpsCall('removeSelfFromOrganizationParticipants', { organizationId, userId })

/*
 * Call to remote reInviteParticipant; organization/{organizationId} and changes must exist
 * @sig reInviteParticipant :: { Id, object } -> Promise void
 */
const reInviteOrganizationParticipant = async (organizationId, changes) =>
    await httpsCall('reInviteOrganizationParticipant', { organizationId, changes })

/*
 * Call to remote changeOrganizationRole
 * @sig changeOrganizationRole :: (Id, Id, Admin|Collaborator) -> Promise void
 */
const changeOrganizationRole = async (organizationId, userId, organizationRole, projectIds) =>
    await httpsCall('changeOrganizationRole', { organizationId, userId, organizationRole, projectIds })

/*
 * Call to remote updateCollaboration, returns {projectId, updateIds}.
 */
const updateCollaboration = async ({ projectId, collaborationId, ...changes }) => {
    // We have to go through each argument and skip passing it down if it's undefined.
    // Otherwise it gets turned into null.
    const changesForFunction = {}
    Object.keys(changes).forEach(change => {
        if (changes[change] !== undefined) changesForFunction[change] = changes[change]
    })

    return await httpsCall('updateCollaboration', {
        projectId,
        collaborationId,
        ...changesForFunction,
    })
}

/*
 * Call to remote updateComment
 * @sig updateComment = ({ projectId: Id, commentId: Id, text: String, completedTimestamp: Int }) -> Promise null
 */
const updateComment = async ({ projectId, commentId, text, completedTimestamp }) =>
    await httpsCall('updateComment', { projectId, commentId, text, completedTimestamp })

/*
 * Call to remote updateUpload
 * Note: we only change 1 field at a time, so changes will only have one of annotations, description, name, tags
 * @sig updateUpload = { projectId: Id, uploadId: Id, changes: Changes } } -> Promise null
 *  changes = { annotations: JSONString } | { description: String | { name: String } | { tags: [IdAndName] }
 *  IdAndName = { id: Id, name: string }
 */
const updateUpload = async ({ projectId, uploadId, changes }) =>
    await httpsCall('updateUpload', { projectId, uploadId, ...changes })

const updateKnockUserToken = async () => await httpsCall('updateKnockUserToken', {})

const getAllDataForProject = async ({ projectId, since }) =>
    await httpsCall('getAllDataForProject', { projectId, since })

const getProjectInfo = async ({ projectIds }) => await httpsCall('getProjectInfo', { projectIds })

const generateIntercomUserHash = async () => await httpsCall('generateIntercomUserHash', {})

const upgradeFromFreePlan = async (organizationId, billingCycle, seatCount) =>
    await httpsCall('upgradeFromFreePlan', { organizationId, billingCycle, seatCount })

const changeSeatCount = async (organizationId, seatCount) =>
    await httpsCall('changeSeatCount', { organizationId, seatCount })

export {
    acceptInvitation,
    addProjectParticipant,
    changeOrganizationRole,
    changeSeatCount,
    createCollaboration,
    createComment,
    createOrganization,
    createProject,
    upgradeFromFreePlan,
    createUpload,
    deleteCanvas,
    deleteCollaboration,
    deleteComment,
    deleteProject,
    deleteUpload,
    generateIntercomUserHash,
    getAllDataForProject,
    getProjectInfo,
    inviteToOrganization,
    reInviteOrganizationParticipant,
    removeInvitation,
    removeOrganizationParticipant,
    removeProjectParticipant,
    removeSelfFromOrganizationParticipants,
    resendInvitation,
    updateCollaboration,
    updateComment,
    updateKnockUserToken,
    updateUpload,
}
