import { Canvas, CanvasSource, Project } from '@range.io/basic-types'
import { pick } from '@range.io/functional'
import LookupTable from '@range.io/functional/src/lookup-table.js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import useBooleanState from '../../components-reusable/hooks/useBooleanState.js'
import MultipageWizard from '../../components-reusable/MultipageWizard.js'
import { ProjectAddedCommand, ProjectMetadata } from '../../firebase/commands/project-added-command.js'
import { useCommandHistory } from '../../firebase/commands/UndoRedo.js'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { DestructiveModal } from '../Modal.js'
import PDFCanvasSourceEditLoading from '../PDFCanvasSourceEditLoading.js'
import NewProjectPage1, { NO_ID } from './NewProjectPage1.js'
import NewProjectPage2 from './NewProjectPage2.js'

const NewProjectWizard = ({ onCancel, onSave }) => {
    const validateName = name => {
        setNameError(name.length ? undefined : 'Enter a project name')
        setName(name)
    }

    const validateAddress = address => {
        setAddressError(address.length ? undefined : 'Specify a project address')
        setAddress(address)
    }

    const validateProjectType = projectType => {
        setProjectTypeError(projectType === NO_ID ? 'Select a project Type' : undefined)
        setProjectType(projectType)
    }

    const handleSave = (canvases, canvasOrder) => {
        onSave({ address, canvases, canvasOrder, center, description, name, projectType, tagSource })
    }

    const organizationProjects = useSelector(ReduxSelectors.userProjectsForSelectedOrganizationAsArray)
    const tagSourceOptions = [{ name: 'Start from scratch', id: NO_ID }].concat(organizationProjects)
    const projectTypeOptions = [{ name: 'Select a project type...', id: NO_ID }].concat(Project.projectTypes)

    const [address, setAddress] = useState('')
    const [center, setCenter] = useState(undefined)
    const [description, setDescription] = useState('')
    const [name, setName] = useState('')
    const [projectType, setProjectType] = useState(projectTypeOptions[0].id)
    const [tagSource, setTagSource] = useState(tagSourceOptions[0].id)

    // errors start off true and are turned OFF as the user sets appropriate values
    // errors are only displayed once the user has tried to click "next"
    const [nameError, setNameError] = useState('Enter a project name')
    const [addressError, setAddressError] = useState('Specify a project address')
    const [projectTypeError, setProjectTypeError] = useState('Select a project Type')

    const [storedCanvasEditorData, setStoredCanvasEditorData] = useState({ canvases: LookupTable([]), canvasOrder: [] })

    const handleCanvasDataUpdate = (canvases, canvasOrder) => setStoredCanvasEditorData({ canvases, canvasOrder })

    const getStoredData = () => storedCanvasEditorData

    const page1Props = {
        address,
        center,
        description,
        name,
        projectType,
        projectTypeOptions,
        tagSource,
        tagSourceOptions,

        nameError,
        addressError,
        projectTypeError,

        setAddress: validateAddress,
        setCenter,
        setDescription,
        setName: validateName,
        setProjectType: validateProjectType,
        setTagSource,
    }

    const page2Props = {
        onCanvasDataUpdate: handleCanvasDataUpdate,
        getStoredData,
    }

    return (
        <MultipageWizard onCancel={onCancel} onSave={handleSave}>
            <NewProjectPage1 {...page1Props} />
            <NewProjectPage2 {...page2Props} />
        </MultipageWizard>
    )
}

NewProjectWizard.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

const Live = () => {
    /*
     * Given a list of canvas objects (given from the canvas source editor) this function builds
     * three lists to be used further - Canvas and CanvasSource entities to be saved to DB and
     * a list of File reference of PDFs to be uploaded to storage
     */
    const prepareProjectCanvasData = (canvasObjects, pdfCanvasOrder, center) => {
        const satelliteCanvas = Canvas.from({ id: v4(), name: 'Satellite Map' })
        const satelliteCanvasSource = CanvasSource.Mapbox.from({
            id: v4(),
            canvasId: satelliteCanvas.id,
            type: 'mapbox',
            name: '',
            center,
            zoom: 18,
            styleUrl: 'mapbox://styles/mapbox/satellite-streets-v11',
        })

        const pdfCanvasSources = canvasObjects.map(canvas =>
            CanvasSource.Pdf.from({
                id: canvas.canvasSource.id,
                canvasId: canvas.id,
                type: 'pdf',
                name: canvas.canvasSource.name,

                // map rotation
                isGeolocated: false,
                userDefinedScale: 48,
                center,
                rotation: 0,
            })
        )
        const pdfCanvases = canvasObjects.map(canvas => Canvas.from({ id: canvas.id, name: canvas.name }))

        const canvases = [satelliteCanvas, ...pdfCanvases]
        const canvasSources = [satelliteCanvasSource, ...pdfCanvasSources]
        const canvasOrder = [satelliteCanvas.id, ...pdfCanvasOrder]
        const files = canvasObjects.map(({ canvasSource }) => pick(['id', 'pdfFile'], canvasSource))
        return { canvases, canvasOrder, canvasSources, files }
    }

    const saveProjectToFirestore = async props => {
        const navigateToProject = () =>
            // delay hiding the loading screen and navigation so all updates get propagated correctly
            // (project gets added to projects)
            setTimeout(() => {
                hideLoading()
                navigate(`/${workspaceId}/${projectId}`)
            }, 5000)

        const {
            address,
            canvasOrder: pdfCanvasOrder,
            canvases: newCanvases,
            center,
            description,
            name,
            projectType,
            tagSource,
        } = props

        const { canvases, canvasOrder, canvasSources, files } = prepareProjectCanvasData(
            newCanvases,
            pdfCanvasOrder,
            center
        )

        const projectId = v4()

        const metadata = ProjectMetadata.from({
            address,
            canvasOrder,
            canvasSources,
            canvases,
            center,
            description,
            name,
            organizationId: organization.id,
            projectId,
            projectType,
            tagSource: tagSource === 'none' ? null : tagSource,
        })

        dispatch(ReduxActions.globalModalDataReset())
        showLoading()
        const success = await runCommand(ProjectAddedCommand.Outbound(metadata, files))
        success ? navigateToProject() : hideLoading()
    }

    const dispatch = useDispatch()
    const organization = useSelector(ReduxSelectors.selectedOrganization)
    const navigate = useNavigate()
    const { workspaceId } = useParams()
    const { runCommand } = useCommandHistory()
    const { get: isLoading, set: showLoading, unset: hideLoading } = useBooleanState(false)
    const { get: isCancelWarningVisible, set: showCancelWarning, reset: hideCancelWarning } = useBooleanState()

    const handleClose = () => showCancelWarning()

    const _handleClose = () => {
        hideCancelWarning()
        navigate('..')
    }

    if (isLoading()) return <PDFCanvasSourceEditLoading.ProjectCreate />
    return (
        <>
            {isCancelWarningVisible() && (
                <DestructiveModal
                    destructiveButtonLabel="Confirm"
                    onDelete={_handleClose}
                    onCancel={hideCancelWarning}
                    primaryLabel="Are you sure you want to cancel your project setup?"
                    secondaryLabel="Your progress will be lost and you will need to start the project setup process again."
                />
            )}
            <NewProjectWizard onCancel={handleClose} onSave={saveProjectToFirestore} />
        </>
    )
}

NewProjectWizard.Live = Live

export default NewProjectWizard
