import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { pick, without } from '@range.io/functional'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { styled } from '../range-theme/index.js'
import {
    Button,
    FlexColumn,
    FlexRow,
    LoadingRingAnimation,
    ScrollArea,
    SelectMenu,
    Text,
    Icon,
} from '../components-reusable/index.js'
import Followers from './Followers.js'
import UserRow from './UserRow.js'
import { ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'

const StyledToggleGroup = styled(FlexRow, {
    borderRadius: '6px',
    width: '100%',
    border: '1px solid $neutral07',
    overflow: 'hidden',
    marginBottom: '8px',
})

const ToggleItem = styled('div', {
    flex: '1 0 auto',
    padding: '6px 10px 6px 10px',
    bg: '$neutral09',
    color: '$neutral05',
    fs: 14,
    fw: 500,
    textAlign: 'center',
    cursor: 'pointer',
    width: '160px',

    '&:hover': {
        backgroundColor: '$primary02',
    },

    '&[aria-checked=true]': {
        bg: '$neutral07',
        color: '$neutral04',
    },
})

const LoadingOverlay = styled(FlexColumn, {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: '$neutral10',
})

const ItemsGroup = ({ items, multiSelect, preventClosing, onItemClick, onCheckboxClick, selected }) => {
    if (!items) return null
    return items.map(option => {
        // We have to double check if the item is selected, because even if item doesn't belong to the selected items group, the user might have just selected it.
        // When user selects or unselects items we don't recalculate the filteredItems, because we don't want to shuffle the list when user is clicking on the items.
        // We only recalculate the filteredItems when user closes the dropdown.
        const isItemActuallySelected = selected?.includes(option.value)

        return (
            <SelectMenu.Option
                key={option.value}
                value={option.value}
                multiSelect={multiSelect}
                onItemClick={onItemClick}
                onCheckboxClick={onCheckboxClick}
                isSelected={isItemActuallySelected}
                preventClosing={preventClosing}
            >
                <UserRow participantShape={option.participantShape} isOnline={option?.isOnline} />
            </SelectMenu.Option>
        )
    })
}

const FollowersSelector = React.forwardRef(
    (
        {
            followers,
            isLoading = false,
            isToggleMode = false,
            participants = [],
            preselectedFollowers = [],
            multiSelect = false,
            preventAutoClosing,
            showFollowersCount = true,
            trigger,
            onFollowerSelected,
            onToggleModeSave,
        },
        contentRef
    ) => {
        const isControlled = followers && onFollowerSelected
        const [selected, setSelected] = useState(isControlled ? followers : preselectedFollowers)
        const [phraseToFilter, setPhraseToFilter] = useState('')
        const [open, setOpen] = useState(false)
        const [openIndex, setOpenIndex] = useState(0)
        const [modeValue, setModeValue] = useState('add')

        useEffect(() => {
            if (followers) setSelected(followers)
        }, [followers])

        const handleItemSelected = value => {
            if (isControlled) onFollowerSelected(value)
            else {
                const shouldRemove = selected?.includes(value)
                setSelected(shouldRemove ? without(value, selected) : [...selected, value])
            }
        }

        const clearSearchInput = () => setPhraseToFilter('')
        const handleClickOutside = () => {
            clearSearchInput()
        }
        const handleItemClicked = value => {
            clearSearchInput()
            handleItemSelected(value)
        }
        const handleItemCheckboxClicked = value => handleItemSelected(value)
        const onOpenChange = open => {
            if (isToggleMode) setOpen(open)
            // Make sure we record whenever the dropdown closes, as it triggers some list recalculations.
            if (!open) setOpenIndex(openIndex + 1)
        }

        // Recalculate the followers list only when these change:
        //  - participants - original followers array has changed
        //  - phraseToFilter - search input value has changed, meaning user wants to narrow down the results
        //  - openIndex - this will be changed when the list was closed.
        //    We don't recalculate the groups right away when the selected items change, because we don't want to shuffle the list immediately.
        const filteredItems = useMemo(() => {
            // Categorize items based on their selected state
            const categorizeBySelection = (option, accumulator) => {
                if (selected?.includes(option.value)) {
                    accumulator.selectedItems.push(option)
                } else {
                    accumulator.notSelectedItems.push(option)
                }
            }

            return participants.reduce(
                (acc, option) => {
                    // If there's no filter phrase, categorize all
                    if (!phraseToFilter.length) {
                        categorizeBySelection(option, acc)
                    } else {
                        // If there's a filter phrase, first check if it matches
                        const fullName = option.participantShape.fullName.toLowerCase()
                        const email = option.participantShape.email
                        const filter = phraseToFilter.toLowerCase()
                        if (fullName.includes(filter) || email.includes(filter)) categorizeBySelection(option, acc)
                    }
                    return acc
                },
                { selectedItems: [], notSelectedItems: [] }
            )
        }, [phraseToFilter, participants, openIndex])

        const followersAvatars = useMemo(() => {
            if (!selected.length) return []
            const avatars = []
            selected.forEach(id => {
                const matchingParticipant = participants.find(participant => participant.value === id)
                if (!matchingParticipant) return
                const avatar = pick(['id', 'avatarFallbackText', 'avatarUrl'], matchingParticipant.participantShape)
                avatars.push(avatar)
            })

            return avatars
        }, [selected, participants])

        const followerCount = selected.length

        return (
            <SelectMenu.Root
                options={participants}
                phraseToFilter={phraseToFilter}
                selectedValues={selected}
                onInteractOutside={handleClickOutside}
                open={isToggleMode ? open : undefined}
                onOpenChange={onOpenChange}
                triggerElement={
                    <SelectMenu.Trigger
                        tooltipText={
                            <div style={{ textAlign: 'center' }}>
                                <span style={{ fontWeight: 'bold', marginBottom: 4 }}>
                                    Edit Followers {showFollowersCount && `(${followerCount})`}
                                </span>
                                <br />
                                <span style={{ fontSize: 12 }}>Followers receive notifications</span>
                            </div>
                        }
                    >
                        {trigger || <Followers followers={followersAvatars} />}
                    </SelectMenu.Trigger>
                }
                ref={contentRef}
            >
                <FlexColumn css={{ position: 'relative', overflow: 'hidden' }}>
                    <FlexColumn css={{ m: 4, gap: 2 }}>
                        <FlexRow css={{ gap: 4, alignItems: 'center', color: '$neutral04' }}>
                            <Icon name="accountUserAdd" iconSize="20" />
                            <Text css={{ fontSize: 16 }}>Followers {showFollowersCount && `(${followerCount})`}</Text>
                        </FlexRow>
                        <Text css={{ fontSize: 14, color: '$neutral05', mb: 6 }}>
                            Followers receive notification updates and Guests can only see pins the are following.
                        </Text>
                    </FlexColumn>
                    {isToggleMode && (
                        <FlexRow>
                            <ToggleGroupPrimitive.Root
                                style={{ width: '100%' }}
                                type="single"
                                value={modeValue}
                                onValueChange={setModeValue}
                            >
                                <StyledToggleGroup>
                                    <ToggleGroupPrimitive.Item
                                        type="single"
                                        value="add"
                                        aria-label="Add followers"
                                        asChild
                                    >
                                        <ToggleItem>
                                            <Text>Add followers</Text>
                                        </ToggleItem>
                                    </ToggleGroupPrimitive.Item>

                                    <ToggleGroupPrimitive.Item
                                        type="single"
                                        value="remove"
                                        aria-label="Remove followers"
                                        asChild
                                    >
                                        <ToggleItem>
                                            <Text>Remove followers</Text>
                                        </ToggleItem>
                                    </ToggleGroupPrimitive.Item>
                                </StyledToggleGroup>
                            </ToggleGroupPrimitive.Root>
                        </FlexRow>
                    )}

                    <SelectMenu.Input
                        placeholder="Search for users..."
                        value={phraseToFilter}
                        onChange={setPhraseToFilter}
                    />
                    <FlexColumn css={{ overflow: 'hidden', flex: '1 1 auto', display: 'grid' }}>
                        <ScrollArea>
                            <ItemsGroup
                                items={filteredItems.selectedItems}
                                multiSelect={multiSelect}
                                onItemClick={handleItemClicked}
                                onCheckboxClick={handleItemCheckboxClicked}
                                selected={selected}
                                preventClosing={preventAutoClosing}
                            />

                            {filteredItems.selectedItems.length && filteredItems.notSelectedItems.length ? (
                                <SelectMenu.Separator />
                            ) : null}

                            <ItemsGroup
                                items={filteredItems.notSelectedItems}
                                multiSelect={multiSelect}
                                onItemClick={handleItemClicked}
                                onCheckboxClick={handleItemCheckboxClicked}
                                selected={selected}
                                preventClosing={preventAutoClosing}
                            />
                        </ScrollArea>
                    </FlexColumn>
                    {isToggleMode && (
                        <FlexColumn>
                            <SelectMenu.Separator
                                css={{
                                    position: 'absolute',
                                    left: 0,
                                }}
                            />

                            <FlexRow
                                css={{
                                    justifyContent: 'space-between',
                                    gap: '8px',
                                    padding: '12px 4px 4px',
                                }}
                            >
                                <Button
                                    css={{
                                        flex: 1,
                                    }}
                                    variant="secondary"
                                    size="lg"
                                    onClick={() => setOpen(false)}
                                    role="button"
                                    tabIndex="0"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    css={{
                                        flex: 1,
                                    }}
                                    variant="primary"
                                    size="lg"
                                    onClick={() => onToggleModeSave(selected, modeValue)}
                                    role="button"
                                    tabIndex="0"
                                >
                                    Confirm
                                </Button>
                            </FlexRow>
                        </FlexColumn>
                    )}

                    {isLoading && (
                        <LoadingOverlay>
                            <LoadingRingAnimation size="xxl" variant="secondary" />
                        </LoadingOverlay>
                    )}
                </FlexColumn>
            </SelectMenu.Root>
        )
    }
)

FollowersSelector.propTypes = {
    participants: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            participantShape: PropTypes.shape(ParticipantShapePropTypes).isRequired,
            isOnline: PropTypes.bool,
        })
    ),
    preselectedFollowers: PropTypes.arrayOf(PropTypes.string), // only values (ids) from participants array
    followers: PropTypes.arrayOf(PropTypes.string), // only values (ids) from participants array
    multiSelect: PropTypes.bool,
    trigger: PropTypes.node,
    onFollowerSelected: PropTypes.func,
}

export default FollowersSelector
