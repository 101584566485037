/*
 * The master component for the Tags component that allows displaying all tags assigned to a given entity
 * and also edit them - assigning or unassigning them from the entity, creating a new TagName if needed
 */

import { TagName } from '@range.io/basic-types'
import { arrayToLookupTable, pluck } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TagNameAddedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { TagShape } from '../react-shapes/tag-shape.js'
import { ReduxSelectors } from '../redux/index.js'
import TagsDisplay from './TagsDisplay.js'
import { convertIdsToTagsObjects } from '../helpers.js'

/*
 * :: ({ inputPlaceholder: String, onTagsChanged: ChangeFunction, tags: [Tag]|[TagShape] })
 */
const Tags = ({ inputPlaceholder, onTagsChanged, tags }) => {
    /*
     * Called when the user has selected an item (by clicking or highlighting and confirming)
     * @sig handleItemSelected :: UUID -> ()
     */
    const handleItemSelected = tagId => {
        const filteredTagIds = selectedTagIds.filter(id => id !== tagId)
        if (filteredTagIds.length === selectedTagIds.length) {
            const tagsArray = convertIdsToTagsObjects([...selectedTagIds, tagId], allTagDatas)
            onTagsChanged(tagsArray)
        } else {
            const newTags = convertIdsToTagsObjects(filteredTagIds, allTagDatas)
            onTagsChanged(newTags)
        }
    }

    /*
     * Called when an assign with a newly created tag has been called
     * @sig handleNewTagAdded :: String -> ()
     */
    const handleNewTagAdded = name => {
        const tagName = TagName.fromName(name)
        runCommand(TagNameAddedCommand.Outbound(tagName))

        const newTag = {
            id: tagName.id,
            name: tagName.name,
        }

        const UUID = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/
        const tagsIds = tags.map(tag => (tag.match(UUID) ? tag : tag.id)) // tags can either be uuids or tags objects
        const tagsArray = convertIdsToTagsObjects(tagsIds, allTagDatas)
        onTagsChanged([...tagsArray, newTag], true)
    }

    /*
     * Called when user clicks on a special item on the tag list. Expected to navigate to the tag manager page.
     */
    const handleTagManagerClicked = () =>
        navigate(`/${workspaceId}/${projectId}/tags`, { state: { safeToNavigateBack: true } })

    const isUsingTagShapes = TagShape.is(tags[0]) // hack until all callers send in TagShapes
    const selectedTagIds = isUsingTagShapes ? pluck('id', tags) : tags
    const { tags: allTagShapes } = useSelector(ReduxSelectors.allShapes)
    const allTagDatas = arrayToLookupTable('id', allTagShapes)

    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const { runCommand } = useCommandHistory()

    return (
        allTagDatas && (
            <TagsDisplay
                variant="standard"
                tags={allTagDatas}
                selectedTagIds={selectedTagIds}
                inputPlaceholder={inputPlaceholder}
                onItemSelectionChange={handleItemSelected}
                onNewTagAdd={handleNewTagAdded}
                onTagManagerClick={handleTagManagerClicked}
            />
        )
    )
}

Tags.propTypes = {
    inputPlaceholder: PropTypes.string,
    onTagsChanged: PropTypes.func.isRequired,
    tags: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string).isRequired, // Tag
        PropTypes.arrayOf(PropTypes.object).isRequired, // TagShape
    ]),
}

export default Tags
