import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useMatches, useSearchParams } from 'react-router-dom'
import { useDebounce } from '../components-reusable/hooks'
import * as Commands2 from '../firebase/commands-2/index.js'
import { ReduxSelectors } from '../redux'

// in case this was queried for before that initial mount of the controller then
// we also need to take into account existing URL
const isUsingEmulator = () =>
    sessionStorage.getItem('useEmulator') === 'true' ||
    new URL(window.location.href).searchParams.get('emulator') !== null

// only expose emulator tools on localhost
if (window.location.href.includes('localhost')) {
    window.isUsingEmulator = isUsingEmulator
    console.log('using emulator', isUsingEmulator())
    window.toggleUseEmulator = () => {
        const currentValue = isUsingEmulator()
        sessionStorage.setItem('useEmulator', !currentValue)
    }
}

/* ---------------------------------------------------------------------------------------------------------------------
 * getStreetMapOpacity/ setStreetMapOpacity
 *
 * Developer tools to change the opacity of the foreground MapBox street map.
 * Calling setStreetMapOpacity in the browser window will change whether the map shows the streets at all
 * and at what opacity.
 *
 * Setting the new opacity to a value greater than 0 will:
 *
 * - set the MapBox style for the foreground map to 'mapbox://styles/mapbox/streets-v11'
 *   as opposed to the default 'mapbox://styles/mapbox/empty-v8'
 * - set the opacity of the canvas showing the street map to the specified opacity.
 *   Note: this will cause the pins to be shown with the given opacity as well, making them harder to see
 *
 * Setting the new opacity to 0
 *
 * - set the MapBox style for the foreground map back to 'mapbox://styles/mapbox/empty-v8'
 * - set the opacity of the canvas showing the street map to 1, so the pins will be fully opaque
 *
 * Notes:
 *
 * - The opacity passed in must be a number between 0 or 1 or nothing changes
 * - You have to refresh the page to see the change have an effect
 * - The opacity is stored in the storageSession with key 'streetMapOpacity' so it will persist between page reloads
 *   (but not between tabs)
 * ------------------------------------------------------------------------------------------------------------------ */

// @sig setStreetMapOpacity :: Number -> ()
const setStreetMapOpacity = newOpacity => {
    const opacity = parseFloat(newOpacity)
    if (isNaN(opacity) || opacity > 1 || opacity < 0)
        console.log(`Expected opacity between 0 and 1; found ${newOpacity}`)
    else sessionStorage.setItem('streetMapOpacity', JSON.stringify(newOpacity))
}

// @sig getStreetMapOpacity :: () -> Number (between 0 and 1)
const getStreetMapOpacity = () => {
    const opacity = JSON.parse(sessionStorage.getItem('streetMapOpacity'))
    return opacity === null || isNaN(opacity) ? 0 : opacity
}

// only expose emulator tools on localhost
window.setStreetMapOpacity = setStreetMapOpacity
window.getStreetMapOpacity = getStreetMapOpacity

/*
 * Utility function to parse current window's URL and process potential
 * query string parameters into cookies.
 * It has to be external because has to works in places where URLController can't be
 * initialized, like the login screen.
 */
const checkUrlForCookies = () => {
    const url = window.location.href
    const urlObject = new URL(url)
    const params = new URLSearchParams(urlObject.search)
    const utmCampaign = params.get('utm_campaign')
    if (utmCampaign) Cookies.set('utm_campaign', utmCampaign)
    const utmMedium = params.get('utm_medium')
    if (utmMedium) Cookies.set('utm_medium', utmMedium)
    const utmSource = params.get('utm_source')
    if (utmSource) Cookies.set('utm_source', utmSource)
}

/*
 * Top level controller component that converts URL parameters to Redux data and vice versa
 * This is key to allow deep linking functionality (so users can copy & paste the URL and land in the exact same place).
 *
 * When the page first loads, the initial URL parameters are converted to values and dispatched to Redux
 * Then, when "interesting" values change in Redux, the current URL is changed.
 *
 * "Interesting" for a specific route is determined by passing `acceptedURLParams` in its route handle.
 * Not all parameters are allowed for every route. In the router configuration every route can pass
 * `acceptedURLParams` in its route handle. That parameter defines which parameters will be displayed in the URL on a
 * specific route.
 *
 * Right now, we only ever handle selectedCollaborationId and mapPosition. The conversions are:
 *
 *                              Redux                                 URL params (via React Router's searchParams)
 *                             ================                      =============================================
 *   selectedCollaborationId   Id                              <=>   [['c', Id]]
 *   mapPosition               { center: { lng, lat }, zoom]   <=>   [['x', Lng], ['y', Lat], ['z', Zoom]]
 *
 *   Lat|Lng|Zoom = Number
 *
 * In addition there is a parameter sent to sessionStorage on initial load (and never sent to Redux):
 *
 *   useEmulator               N/A                     'emulator'
 *
 * To allow route nesting we need to have React Router Data APIs enabled.
 */

let canvasId
let collaborationId
let lng
let lat
let zoom

const URLController = () => {
    /*
     * Inbound: one-time action to convert the initial URL parameters to Redux values.
     *
     * (After the first conversion, we cause any further changes to the URL parameters ourselves via changeCurrentUrl,
     * so it's pointless to reparse them when they have changed.)
     */
    const initializeStateFromURL = () => {
        const emulatorParamHandler = () => sessionStorage.setItem('useEmulator', true)

        const paramHandlers = {
            emulator: emulatorParamHandler,
            c: id => (collaborationId = id),
            x: x => (lng = Number(x)),
            y: y => (lat = Number(y)),
            z: z => (zoom = Number(z)),
        }

        canvasId = matches.at(-1).params.canvasId

        for (const [key, value] of searchParams.entries()) {
            if (paramHandlers[key]) paramHandlers[key](value)
            else console.warn(`Unhandled URL parameter: ${key} with value: ${value}`)
        }
    }

    /*
     * Outbound: when the values in Redux change ("enough," in the case of the map moving), update the web page's URL
     */
    const changeCurrentUrl = () => {
        /*
         * update ReactRouter's searchParams by adding [key, value] pairs
         * selectedCollaborationId and mapPosition are the only params we send to the URL right now
         *
         * mapPosition     { center: { lng, lat }, zoom]   =>   [['x', Lng], ['y', Lat], ['z', Zoom]]
         * collaborationId   Id                            => ['c', Id]
         *
         * Lat/Lng/Zoom = Number
         */
        const buildParamPair = paramName => {
            if (paramName === 'selectedCollaborationId') {
                // get the CURRENT value from Redux; this function will be called after the dispatch in
                // selectedCollaborationHandler -- but before URLController is called again (because it's a useEffect)
                const collaborationId = ReduxSelectors.selectedCollaborationId(getState())
                return collaborationId ? [['c', collaborationId]] : null
            }

            if (paramName === 'mapPosition')
                return [
                    ['x', mapPosition.center.lng],
                    ['y', mapPosition.center.lat],
                    ['z', mapPosition.zoom],
                ]
            return null
        }

        const mostSpecificMatch = matches.at(-1)
        const acceptedURLParams = mostSpecificMatch?.handle?.acceptedURLParams ?? []
        const paramPairs = acceptedURLParams.flatMap(buildParamPair).filter(a => !!a)
        const newSearchParams = new URLSearchParams(paramPairs)
        setSearchParams(newSearchParams)
    }

    /*
     * Select the canvas and collaboration AFTER loading the project; otherwise loading itself will wipe out our values
     * Inbound; only has an effect one time, (because the dispatches are guarded by values we remove)
     */
    const uploadDataAfterProjectHasLoaded = () => {
        if (!isProjectDataLoaded) return

        Commands2.selectionChanged({ canvasId, collaborationId, lng, lat, zoom, context: 'urlChanged' })

        // we don't want to do this again
        canvasId = undefined
        collaborationId = undefined
        lat = undefined
        lng = undefined
        zoom = undefined
    }

    // main
    const [searchParams, setSearchParams] = useSearchParams()
    const matches = useMatches()
    const { getState } = useStore()
    const mapPosition = useSelector(ReduxSelectors.mapPosition)
    const selectedCollaborationId = useSelector(ReduxSelectors.selectedCollaborationId)
    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const isProjectDataLoaded = useSelector(ReduxSelectors.isProjectDataLoaded)

    // we don't want the URL to update on every map move during animations so lets debounce it
    const debouncedMapPosition = useDebounce(mapPosition, 100)

    // URLController works entirely by side-effect...
    useEffect(initializeStateFromURL, [])
    useEffect(changeCurrentUrl, [debouncedMapPosition, selectedCollaborationId, selectedCanvas])
    useEffect(uploadDataAfterProjectHasLoaded, [isProjectDataLoaded])

    // ...and returns null
    return null
}

export { checkUrlForCookies, isUsingEmulator }

export default URLController
