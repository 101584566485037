/*
 * See Readme
 */

import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import CommandPlayer, { simpleInboundChangedRunCommand } from './command-player.js'
import { reInviteOrganizationParticipant } from './https-calls.js'
import { Invitation } from '@range.io/basic-types'
import { ReduxActions } from '../../redux/index.js'
import verifyAndUpdateSeatsCount from './helpers/verify-and-update-seats-count.js'

// ---------------------------------------------------------------------------------------------------------------------
// OrganizationParticipantReInvitedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationParticipantReInvitedCommand = taggedSum('OrganizationParticipantReInvitedCommand', {
    Inbound: { invitation: 'Invitation' },
    Outbound: {
        organizationId: StringTypes.Id,
        changes: 'Object', // userId, projectIds
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A OrganizationParticipantReInvitedCommand.Inbound has arrived from Firestore; send it to redux
 */
const runInboundCommand = (resources, command) =>
    simpleInboundChangedRunCommand(
        resources,
        command,
        Invitation,
        ReduxActions.invitationChanged,
        command => command.invitation
    )

/*
 * Change the Organization.participant's organizationRole
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError, getState } = resources
    const { organizationId, changes } = command

    const { userFullName, ...changesWithoutUserFullName } = changes

    try {
        // If we add Collaborators or Admins, check if we have enough seats and update if more needed
        if (changes.organizationRole !== 'Guest') {
            verifyAndUpdateSeatsCount(getState(), 1)
        }

        const invitation = await reInviteOrganizationParticipant(organizationId, changesWithoutUserFullName)
        dispatch(ReduxActions.invitationChanged(invitation))

        // show the toast
        const toastLabel = `Invitation sent to ${userFullName}`
        const toast = { id: invitation.id, severity: 'success', toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))
    } catch (e) {
        displayError(e)
    }
}

const addOrganizationParticipantReInvitedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationParticipantReInvitedCommand,
        CommandPlayer({
            CommandType: OrganizationParticipantReInvitedCommand,
            Type: Invitation,
            collectionPath: organizationId => `/organizations/${organizationId}/invitations/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationParticipantReInvitedCommand, addOrganizationParticipantReInvitedCommandSingleton }
