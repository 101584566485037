import PropTypes from 'prop-types'
import React from 'react'
import { keyframes, styled } from '../range-theme'

const ringAnimation = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
})

const StyledRingAnimationContainer = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',

    div: {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: '24px',
        height: '24px',
        border: '2px solid currentColor',
        borderRadius: '50%',
        animation: `${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
        borderColor: 'currentColor transparent transparent transparent',

        '&.r-1': { animationDelay: '-0.45s' },
        '&.r-2': { animationDelay: '-0.3s' },
        '&.r-3': { animationDelay: '-0.15s' },
        '&.r-4': { animationDelay: '0s' },
    },
    variants: {
        variant: {
            primary: {
                div: {
                    borderColor: '$pink03 transparent transparent transparent',
                },
            },
            secondary: {
                div: {
                    borderColor: '$neutral05 transparent transparent transparent',
                },
            },
            tertiary: {
                div: {
                    borderColor: 'white transparent transparent transparent',
                },
            },
        },
        size: {
            sm: {
                div: {
                    width: '14px',
                    height: '14px',
                },
            },
            md: {
                div: {
                    width: '14px',
                    height: '14px',
                },
            },
            lg: {
                div: {
                    width: '24px',
                    height: '24px',
                },
            },
            xl: {
                div: {
                    width: '24px',
                    height: '24px',
                },
            },
            xxl: {
                div: {
                    width: '36px',
                    height: '36px',
                },
            },
        },
    },
})

const LoadingRingAnimation = ({ variant = 'tertiary', size = 'sm', ...rest }) => (
    <StyledRingAnimationContainer
        className="loading-ring"
        data-cy="loading-ring"
        variant={variant}
        size={size}
        data-size={size}
        {...rest}
    >
        <div className="r-1" />
        <div className="r-2" />
        <div className="r-3" />
        <div className="r-4" />
    </StyledRingAnimationContainer>
)

LoadingRingAnimation.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
}

export default LoadingRingAnimation
