import { CanvasSource, LngLatMath, PdfPage } from '@range.io/basic-types'
import { LRU, tagged } from '@range.io/functional'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mark } from '../firebase/console.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'

const { boundsFromCenterAndSize } = LngLatMath

/*
 * Compute the x, y, width, height we need to give to context.drawImage
 *
 * We're trying to copy the entire offscreen canvas into the onscreen canvas at the appropriate position and scale:
 *
 *     context.drawImage(offscreenCanvas, 0, 0, offscreen pixel width, offscreen pixel height, x, y, width, height)
 *
 * This function will compute x, y, width, height we need to give to context.drawImage
 *
 * Suppose
 *
 * - the  onscreen canvas is 1000 x 1000 pixels and represents a bounds of ( w: 5.0, s: 5.0, e: 5.5, n: 5.5 }
 * - the offscreen canvas is 1600 x 1600 pixels and represents a bounds of ( w: 5.1, s: 5.1, e: 5.2, n: 5.2 }
 *
 * (The bounds of the ONSCREEN canvas vary as the user drags and zooms. The bounds of the OFFSCREEN screen are fixed;
 * see the notes below.)
 *
 * In this case, the offscreen canvas fits entirely into the onscreen canvas
 *
 *                                 lng
 *                     +----+----+----+----+----+
 *                    5.0  5.1  5.2  5.3  5.4  5.5
 *
 *                               pixels
 *                     +----+----+----+----+----+
 *                     0   200  400  600  800  1000
 *     +  5.5       0  +----+----+----+----+----+
 *     |               |                        |
 *     +  5.4     200  +                        +
 *     |               |                        |
 * lat +  5.3     400  +                        +
 *     |               |                        |
 *     +  5.2     600  +    +----+              +
 *     |               |    |    |              |
 *     +  5.1     800  +    +----+              +
 *     |               |                        |
 *     +  5.0    1000  +----+----+----+----+----+
 *
 * Since we scaled the offscreen canvas down from 1600 x 1600 to 200 x 200, we multiplied both dimensions by 1/8,
 * which works out to be
 *
 *                    onscreen pixel width     offscreen lng delta                   1000   0.1              1
 *     width scale  = ---------------------  * -------------------               =   ---- * ---          =   -
 *                    offscreen pixel width    onscreen lng delta                    1600   0.5              8
 *
 *                    onscreen pixel height    offscreen lat delta                   1000   0.1              1
 *     height scale = ---------------------  * -------------------               =   ---- * ---          =   -
 *                    offscreen pixel height   onscreen lat delta                    1600   0.5              8
 *
 * then the target onscreen width and height:
 *
 *     width        = width scale  * offscreen pixel width                       =   1/8 * 1600          =  200
 *     height       = height scale * offscreen pixel height                      =   1/8 * 1600          =  200
 *
 *
 * Notes: the bounds of the ONSCREEN canvas vary as the user drags and zooms.
 *
 * The bounds of the OFFSCREEN screen are fixed: the offscreen canvas exactly covers the area of a PDF Canvas Source,
 * which has a center and scale. If a blueprint is 3 feet wide x 2 feet tall with a scale of 100 : 1,
 * the area covered by the blueprint is 300 feet x 200 feet.
 *
 * Since it also has a center, we therefore know its "real world" bounds.
 *
 * The PDF Canvas Source will also have a rotation, so if we were to try to show it on a map, we could:
 *
 * A. show the MAP facing north with the blueprint at the appropriate world position and rotation OR
 * B. show the BLUEPRINT facing north with the map rotated "underneath" the blueprint.
 *
 * Because it's hard to read a blueprint that's at any rotation other than "up" we choose "B" -- to show the
 * PDF Canvas Source facing north.
 *
 *
 * The height/width is even more complicated if the canvas is rotated, since the onscreen and offscreen canvases
 * don't even completely overlap, they're at relative angles to one another. Fortunately, we can just change
 * (a copy of) the map's transform back to an angle of 0 and the two canvases will no longer be at angles of each other.
 *
 * Finding the x and y values
 *
 * In theory, to find the x and y values, since we know the lat/lng of the center of our offscreen canvas,
 * we just need to use map.project to convert it to a point in the onscreen canvas.
 *
 * In practice, we also have to:
 *
 * - take the screen's pixel ratio into account
 * - move x and y up and left by half the (offscreen) pixel width and height
 *
 * Context.drawImage draws (down) from the top-left, so if we use the x, y we got for the center of the source image,
 * it would be too far down and right
 *
 *
 * @sig  projectOffscreenOntoOnscreen :: (MapboxMap, ) -> [Number, Number, Number, Number]
 *  Bounds = { west: Number, east: Number, south: Number, north: Number }
 *  Dimensions = { width: Number, height: Number }
 */
const projectOffscreenOntoOnscreen = (foregroundMapboxMap, renderedCanvas) => {
    const { pdfBounds: offscreenBounds, pdfSizeInPixels: offscreenCanvasDimensions } = renderedCanvas
    const onscreenCanvasDimensions = foregroundMapboxMap.getCanvas()

    // "un-rotate" a copy of the foreground map's transform so the bounds of the two canvases overlap each other
    const zeroRotationTransform = foregroundMapboxMap.transform.clone()
    zeroRotationTransform.bearing = 0
    const zeroRotationBounds = zeroRotationTransform.getBounds()

    const zeroRotationEast = zeroRotationBounds.getEast()
    const zeroRotationWest = zeroRotationBounds.getWest()
    const zeroRotationNorth = zeroRotationBounds.getNorth()
    const zeroRotationSouth = zeroRotationBounds.getSouth()

    const onscreen = {
        east: zeroRotationEast,
        west: zeroRotationWest,
        north: zeroRotationNorth,
        south: zeroRotationSouth,
        pixelWidth: onscreenCanvasDimensions.width,
        pixelHeight: onscreenCanvasDimensions.height,
        latDelta: zeroRotationNorth - zeroRotationSouth,
        lngDelta: zeroRotationEast - zeroRotationWest,
    }

    const offscreen = Object.assign(offscreenBounds, {
        pixelWidth: offscreenCanvasDimensions.width,
        pixelHeight: offscreenCanvasDimensions.height,
        latDelta: offscreenBounds.north - offscreenBounds.south,
        lngDelta: offscreenBounds.east - offscreenBounds.west,
    })

    // -----------------------------------------------------------------------------------------------------------------
    // compute height and width
    // -----------------------------------------------------------------------------------------------------------------
    const widthScale = (offscreen.lngDelta * onscreen.pixelWidth) / (offscreen.pixelWidth * onscreen.lngDelta)
    const heightScale = (offscreen.latDelta * onscreen.pixelHeight) / (offscreen.pixelHeight * onscreen.latDelta)
    const width = offscreen.pixelWidth * widthScale
    const height = offscreen.pixelHeight * heightScale

    // -----------------------------------------------------------------------------------------------------------------
    // compute x and y
    // -----------------------------------------------------------------------------------------------------------------
    const centerLat = (offscreenBounds.north + offscreenBounds.south) / 2
    const centerLng = (offscreenBounds.west + offscreenBounds.east) / 2
    const { x, y } = foregroundMapboxMap.project({ lng: centerLng, lat: centerLat })

    // This gives us the device pixel ratio
    const rect = onscreenCanvasDimensions.getBoundingClientRect()
    const scale = onscreenCanvasDimensions.width / rect.width

    return { x: x * scale - width / 2, y: y * scale - height / 2, width, height }
}

/*
 * For efficiency, we want to call PDFJS as little as possible, so we:
 *
 * 1 Draw the PDF into an offscreen (DOM) canvas
 * 2 Copy parts of the offscreen canvas into the onscreen canvas as controlled by the current zoom and center
 *
 * Every PDF has an inherent width and height based on Postscript's definition of 72 points per inch.
 * (This width and height was set, explicitly or not, by the creator of the PDF.)
 *
 * PDFJS can draw a PDF at a scale other than 1:1 (this is the "scale" passed to PdfPage.getViewport)
 * If our PDF is 1500 pixels wide and we (arbitrarily) decide to set the PDFJS scale to 4.0, we will need an
 * offscreen canvas 6000 pixels wide.
 */

// ---------------------------------------------------------------------------------------------------------------------
// OffscreenCanvasWrapper
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Object to store an offscreen (DOM) canvas, its pixel bounds and its geospatial coordinates
 */
export const OffscreenCanvasWrapper = tagged('OffscreenCanvasWrapper', {
    offscreenCanvas: 'Object', // HTML Canvas
    pdfSizeInPixels: 'Object', // { width: Number, height: Number }
    pdfBounds: 'Object', // Bounds = { north; LngLat, east: LngLat, south: LngLat, west: LngLat }
})

/*
 * Create a small dummy canvas for now (red 300x200)
 */
const fromMapCanvasSource = async (canvasSource, center) => {
    const width = 300
    const height = 200

    // create a new offscreen (DOM) canvas element and render the PDF page into it
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = width
    offscreenCanvas.height = height

    const pdfSizeInPixels = { width, height }
    const pdfBounds = boundsFromCenterAndSize({ lng: center[0], lat: center[1] }, width, height)
    const context = offscreenCanvas.getContext('2d')
    context.width = width
    context.height = height
    context.fillStyle = 'lightgray'
    context.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height)

    // Create a OffscreenCanvasWrapper and cache it
    const renderedCanvas = OffscreenCanvasWrapper(offscreenCanvas, pdfSizeInPixels, pdfBounds)
    renderedCanvases.setItem(canvasSource.id, renderedCanvas) // store the OffscreenCanvasWrapper in the LRU
    return renderedCanvas
}

/*
 * Render the selected CanvasSource into an offscreen (DOM) canvas object and return a RenderedObject for it
 * NOTE: If the CanvasSource is not a PDF, create a dummy canvas for now
 *
 * The result is memoized using an LRU limited to MAX_OFFSCREEN_CANVASES
 * @sig fromCanvasSource :: (CanvasSource, [Number, Number]) -> OffscreenCanvasWrapper
 */
OffscreenCanvasWrapper.fromCanvasSource = async (canvasSource, center) => {
    if (!CanvasSource.Pdf.is(canvasSource)) return fromMapCanvasSource(canvasSource, center)

    // when drawing offscreen, scale the PDF up by this factor to allow for better sampling when zoomed in
    const PDF_SCALE = 4

    // first try to pull the OffscreenCanvasWrapper from the LRU cache
    const url = canvasSource.pdfUrl
    const previouslyRenderedCanvas = renderedCanvases.getItem(url)
    if (previouslyRenderedCanvas) return previouslyRenderedCanvas

    // read a page of the PDF
    const pdfPage = await PdfPage.fromUrl(url)

    // scale the offscreen viewport up by PDF_SCALE
    const viewport = PdfPage.getViewport(pdfPage, PDF_SCALE)

    //  compute pixel dimensions of offscreen canvas and (geospatial) bounds
    const [w, h] = [viewport.width / PDF_SCALE, viewport.height / PDF_SCALE]

    const { width: pdfWidthInMeters, height: pdfHeightInMeters } = CanvasSource.dimensionsInMeters(canvasSource, w, h)

    const pdfSizeInPixels = { width: viewport.width, height: viewport.height }
    const pdfBounds = boundsFromCenterAndSize({ lng: center[0], lat: center[1] }, pdfWidthInMeters, pdfHeightInMeters)

    // create a new offscreen (DOM) canvas element and render the PDF page into it
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = pdfSizeInPixels.width
    offscreenCanvas.height = pdfSizeInPixels.height
    await PdfPage.render(pdfPage, offscreenCanvas.getContext('2d'), viewport)

    // Create a OffscreenCanvasWrapper and cache it
    const renderedCanvas = OffscreenCanvasWrapper(offscreenCanvas, pdfSizeInPixels, pdfBounds)
    renderedCanvases.setItem(url, renderedCanvas) // store the OffscreenCanvasWrapper in the LRU
    return renderedCanvas
}

// cache up to MAX_OFFSCREEN_CANVASES offscreen RenderedCanvases
const MAX_OFFSCREEN_CANVASES = 10
const renderedCanvases = LRU(MAX_OFFSCREEN_CANVASES)

const StyledPdfCanvas = styled('canvas', {
    zIndex: -100, // needs to be behind the foreground map, but goes after it in document-order
})

// ---------------------------------------------------------------------------------------------------------------------
// Map component
// ---------------------------------------------------------------------------------------------------------------------
let recenterListener
const BackgroundPdfMap = props => {
    /*
     * Recenter the background map
     */
    const recenterBackgroundMap = renderedCanvas => {
        const { offscreenCanvas, pdfSizeInPixels } = renderedCanvas
        const onscreenCanvas = onscreenCanvasRef.current

        if (!onscreenCanvas) return

        const context = onscreenCanvas.getContext('2d', { willReadFrequently: true })
        const { x, y, width, height } = projectOffscreenOntoOnscreen(foregroundMapboxMap, renderedCanvas)

        // the destination is always the entire canvas
        context.clearRect(0, 0, onscreenCanvas.width, onscreenCanvas.height)
        context.drawImage(offscreenCanvas, 0, 0, pdfSizeInPixels.width, pdfSizeInPixels.height, x, y, width, height)
    }

    // the recenterListener is called whenever the foreground map moves or the window resizes so that the
    // background PDF is kept in sync. However, when the foregroundMap, selectedCanvas or URL changes,
    // we need a new recenterListener and need to remove the old listener from the foreground map and window
    // This is also true when unmounting the BackgroundPdfMap
    const removeListeners = () => {
        if (!recenterListener) return

        if (foregroundMapboxMap) foregroundMapboxMap.off('move', recenterListener)
        window.removeEventListener('resize', recenterListener)
    }

    // initialize the PDF document
    const startup = async () => {
        if (!foregroundMapboxMap || !selectedCanvasSource || !selectedCanvasSource.pdfUrl) return null

        const measurePdfRendering = mark('PDFRendering')

        dispatch(ReduxActions.pdfLoadingStateChanged('loading'))

        const renderedCanvas = await OffscreenCanvasWrapper.fromCanvasSource(selectedCanvasSource, center)
        recenterBackgroundMap(renderedCanvas)

        dispatch(ReduxActions.pdfLoadingStateChanged('loaded'))

        // remove old listeners and create new ones
        removeListeners()

        // redraw background map when foreground map moves
        recenterListener = () => recenterBackgroundMap(renderedCanvas)
        foregroundMapboxMap.on('move', recenterListener)
        window.addEventListener('resize', recenterListener)

        measurePdfRendering()
    }

    const dispatch = useDispatch()

    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const selectedCanvasSource = useSelector(ReduxSelectors.pdfCanvasSourceForSelectedCanvas)
    const onscreenCanvasRef = useRef(null)
    const { mapboxMap: foregroundMapboxMap, canvasSource, ...otherProps } = props
    const { center } = canvasSource

    useEffect(() => {
        startup() // without this style, (function call and braces) React complains about "async within useEffect"
    }, [foregroundMapboxMap, selectedCanvasSource])
    useEffect(() => () => removeListeners(), []) // unmount

    if (!foregroundMapboxMap) return null
    if (!selectedCanvas) return null

    const mapboxCanvas = foregroundMapboxMap.getCanvas()

    const style = {
        width: mapboxCanvas.style.width,
        height: mapboxCanvas.style.height,
    }

    return (
        <StyledPdfCanvas
            ref={onscreenCanvasRef}
            id="background-map-container"
            width={mapboxCanvas.width}
            height={mapboxCanvas.height}
            style={style}
            {...otherProps}
        />
    )
}

export { fromMapCanvasSource, projectOffscreenOntoOnscreen }
export default BackgroundPdfMap
