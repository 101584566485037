import React, { useEffect } from 'react'
import { useStore } from 'react-redux'
import { v4 } from 'uuid'
import { useKeyMaps } from '../components-reusable/hooks'

import {
    Box,
    Button,
    DatePicker,
    FlexColumn,
    FlexRow,
    Icon,
    SingleSelectUser,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import CreatingReportInfoModal from './CreatingReportInfoModal'
import EditTagsModal from './EditTagsModal.js'
import ListViewArchiveTasksModal from './ListViewArchiveTasksModal.js'
import ListViewDeleteTasksModal from './ListViewDeleteTasksModal.js'
import useReport from './reports/TaskListReport'
import StatusSelector from './StatusSelector.js'
import { FollowButton } from './FollowButton.js'
import FollowersSelector from './FollowersSelector.js'

const BulkActionsHeaderWrapper = styled(FlexColumn, {
    width: '100%',
    color: '$neutral04',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '16px',
    borderTop: '1px solid $neutral07',
    background: '$neutral08',
    position: 'relative',
})

const ControlsContainer = styled(FlexRow, {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    flexWrap: 'wrap',
})

const CollaborationSelectedCountLabel = styled(Text, {
    color: '$neutral04',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
})

const ListWrapper = styled(FlexColumn, {
    mt: '44px',
    background: '$neutral10',
    color: '$neutral05',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    position: 'absolute',
    zIndex: 200,
    boxShadow: '0px 6px 10px #00000010',
    boxSizing: 'border-box',
    maxWidth: '250px',
})

const ListViewBulkActions = ({
    hasRightsToArchive,
    hasRightsToDelete,
    checkedItems,
    onClearAllClick,
    onDelete,
    dueDate,
    onDueDateChanged,
    onDeleteItems,
    onArchiveItems,
    allAvailableAssigneesShapes,
    bulkActionSelectedAssignee,
    onBulkActionSelectedAssignee,
    areAllCheckedTasksArchived = false,
    areAllTasksFollowed = false,
    isFollowingInProgress = false,
    statusNames,
    bulkActionSelectedStatus,
    onBulkActionSelectedStatus,
    onBulkActionTagsChange,
    onBulkActionDateChange,
    onBulkActionFollowersChange,
    onBulkFollowButtonClick,
    onExport,
}) => {
    const resetReport = () => {
        reportPromiseRef?.current && reportPromiseRef.current.cancel()
        setIsReportInProgress(false)
    }

    const closeActiveElements = () => {
        setShowDeleteTasksModal(false)
        setShowArchiveTasksModal(false)
        setShowTagsEditModal(false)
        setShowAssigneeList(false)
        setShowStatusList(false)
        setShowDatePicker(false)
        document.activeElement.blur()
    }

    // close active elements on Escape key down
    useEffect(() => {
        pushKeyMap('ListViewBulkActions', { Escape: closeActiveElements })
        return () => popKeyMap('ListViewBulkActions')
    }, [])

    // check for mousedown outside the list and stop showing the list
    useEffect(() => {
        const listener = event => {
            if (!listRef?.current || !listRef.current.contains(event.target)) setShowAssigneeList(false)
        }
        document.addEventListener('mousedown', listener)
        return () => document.removeEventListener('mousedown', listener)
    }, [])

    useEffect(() => {
        // we want to reset the report whenever the content for it changed (here, the amount of selected tasks)
        resetReport()
    }, [checkedItems])

    const toggleAssignee = userId => {
        onBulkActionSelectedAssignee(userId)
        setShowAssigneeList(false)
    }

    const hideTagsModal = () => setShowTagsEditModal(false)

    const onEditTagsConfirm = (editType, items) => {
        onBulkActionTagsChange({ items, editType })
        hideTagsModal()
    }

    const handleReportButtonClicked = () => {
        setIsReportInProgress(true)
        // delay it a bit so React gets time to update and show the modal before starting the creation of the PDF
        setTimeout(() => {
            const newReportPromise = prepareReport(checkedItems)
            reportPromiseRef.current = newReportPromise
            reportPromiseRef.current.promise.then(reportBlob => {
                Segment.sendTrack('task report created', v4(), ReduxSelectors.paramsForTrackEvent(getState()))

                openReport(reportBlob)
                setIsReportInProgress(false)
            })
        }, 10)
    }

    const singleSelectUserProps = {
        projectParticipantShapes: allAvailableAssigneesShapes,
        selectedUser: bulkActionSelectedAssignee,
        setSelectedUserId: toggleAssignee,
    }

    const checkedTasksCountText =
        checkedItems.length > 1 ? `${checkedItems.length} tasks selected` : `${checkedItems.length} task selected`

    const [showDeleteTasksModal, setShowDeleteTasksModal] = React.useState()
    const [showArchiveTasksModal, setShowArchiveTasksModal] = React.useState()
    const [showTagsEditModal, setShowTagsEditModal] = React.useState()
    const [showAssigneeList, setShowAssigneeList] = React.useState()
    const [showStatusList, setShowStatusList] = React.useState()
    const [showDatePicker, setShowDatePicker] = React.useState()
    const [participantsForFollowersSelector, setParticipantsForFollowersSelector] = React.useState([])
    const listRef = React.useRef()
    const { pushKeyMap, popKeyMap } = useKeyMaps()
    const { prepareReport, openReport } = useReport()
    const [isReportInProgress, setIsReportInProgress] = React.useState(false)
    const reportPromiseRef = React.useRef(null)
    const { getState } = useStore()

    useEffect(() => {
        const toFollowersSelectorItem = participantShape => {
            return {
                value: participantShape.id,
                participantShape,
            }
        }

        const participantsInFollowerFormat = allAvailableAssigneesShapes.map(toFollowersSelectorItem) // map it to follower format, so we get online state and value for the list

        setParticipantsForFollowersSelector(participantsInFollowerFormat)
    }, [allAvailableAssigneesShapes])

    return (
        <BulkActionsHeaderWrapper data-cy="list-view-bulk-actions">
            {showDeleteTasksModal && (
                <ListViewDeleteTasksModal onCancel={() => setShowDeleteTasksModal(false)} onDelete={onDeleteItems} />
            )}
            {showArchiveTasksModal && (
                <ListViewArchiveTasksModal
                    onCancel={() => setShowArchiveTasksModal(false)}
                    onArchive={() => {
                        onArchiveItems()
                        setShowArchiveTasksModal(false)
                    }}
                    areAllCheckedTasksArchived={areAllCheckedTasksArchived}
                />
            )}
            {showTagsEditModal && (
                <EditTagsModal
                    onCancel={hideTagsModal}
                    onConfirm={onEditTagsConfirm}
                    onDelete={hideTagsModal}
                    addTagsDescription="Add additional, new tags to the selected tasks."
                    replaceTagsDescription="Replace ALL existing tags with new tags for the selected tasks. ALL existing tags will
                be removed and replaced with new tags."
                />
            )}
            {isReportInProgress && <CreatingReportInfoModal onCancel={resetReport} />}
            <ControlsContainer>
                <CollaborationSelectedCountLabel data-cy="bulk-actions-tasks-count">
                    {checkedTasksCountText}
                </CollaborationSelectedCountLabel>
                <Button
                    variant="primary"
                    size="lg"
                    disabled={isReportInProgress}
                    onClick={handleReportButtonClicked}
                    css={{ minWidth: '150px' }}
                >
                    {isReportInProgress ? (
                        <Icon name="loading" iconSize="16px" />
                    ) : (
                        <>
                            <Icon name="library" iconSize="16px" />
                            <Text>Create Report</Text>
                        </>
                    )}
                </Button>
                <FlexColumn>
                    <Button variant="secondary" size="lg" onClick={() => setShowDatePicker(val => !val)}>
                        <Icon name="calendar" iconSize="16px" />
                        <Text>Due Date</Text>
                    </Button>

                    {showDatePicker && (
                        <Box css={{ position: 'absolute', mt: '44px', zIndex: 200 }}>
                            <DatePicker
                                onChange={dueDate => {
                                    onBulkActionDateChange(dueDate)
                                    setShowDatePicker(false)
                                }}
                                value={dueDate}
                            />
                        </Box>
                    )}
                </FlexColumn>

                <Button variant="secondary" size="lg" onClick={() => setShowTagsEditModal(val => !val)}>
                    <Icon name="tags" iconSize="16px" />
                    <Text>Bulk Tag Editor</Text>
                </Button>
                <FlexColumn ref={listRef} css={{ maxWidth: '120px' }}>
                    {showAssigneeList ? (
                        <SingleSelectUser
                            withoutIcon
                            withNoAssigneeOption
                            css={{ height: '40px' }}
                            {...singleSelectUserProps}
                        />
                    ) : (
                        <Button variant="secondary" size="lg" onClick={() => setShowAssigneeList(val => !val)}>
                            <Icon name="user" iconSize="16px" />
                            <Text>Assignee</Text>
                        </Button>
                    )}
                </FlexColumn>
                <FlexColumn>
                    <Button variant="secondary" size="lg" onClick={() => setShowStatusList(true)}>
                        <Icon name="status" iconSize="16px" />
                        <Text>Status</Text>
                    </Button>
                    {showStatusList && (
                        <ListWrapper ref={listRef}>
                            <StatusSelector
                                displayAsList
                                allStatusNames={statusNames}
                                initialStatusName={statusNames[bulkActionSelectedStatus]}
                                onStatusChanged={onBulkActionSelectedStatus}
                                onOpenChange={isOpen => setShowStatusList(isOpen)}
                                defaultOpen={showStatusList}
                            />
                        </ListWrapper>
                    )}
                </FlexColumn>
                {hasRightsToArchive && (
                    <Button variant="secondary" size="lg" onClick={() => setShowArchiveTasksModal(true)}>
                        <Icon name="archive" iconSize="16px" />
                        <Text>{areAllCheckedTasksArchived ? 'Unarchive' : 'Archive'}</Text>
                    </Button>
                )}
                <FollowButton
                    onClick={onBulkFollowButtonClick}
                    isFollowing={areAllTasksFollowed}
                    disabled={isFollowingInProgress}
                />
                <FollowersSelector
                    multiSelect
                    participants={participantsForFollowersSelector}
                    followers={[]}
                    preventAutoClosing
                    isLoading={isFollowingInProgress}
                    isToggleMode={true}
                    showFollowersCount={false}
                    onToggleModeSave={onBulkActionFollowersChange}
                    trigger={
                        <Button variant="secondary" size="lg" onClick={() => setShowStatusList(true)}>
                            <Icon name="followers" iconSize="16px" />
                            <Text>Edit Followers</Text>
                        </Button>
                    }
                />
                <Button variant="secondary" size="lg" onClick={onExport}>
                    <Icon name="exportAction" iconSize="16px" />
                    <Text>Export</Text>
                </Button>
                {hasRightsToDelete && (
                    <Button
                        css={{ pr: '12px', gap: '4px' }}
                        variant="destructiveSecondary"
                        size="lg"
                        onClick={() => setShowDeleteTasksModal(true)}
                    >
                        <Icon name="trash" iconSize="16px" />
                        <Text>Delete</Text>
                    </Button>
                )}
                <Button css={{ ml: 'auto' }} variant="secondary" size="lg" onClick={onClearAllClick}>
                    <Text>Done</Text>
                </Button>
            </ControlsContainer>
        </BulkActionsHeaderWrapper>
    )
}

export default ListViewBulkActions
